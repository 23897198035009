export interface PortfolioReviewReport {
  Reporting_Date: string;
  Fund_Name: string;
  portfolio_companyName: string;
  company_ID: string;
  fund_ID: string;
  Textboxes_QtrUpdate: string;
  portfolio_fiscalYearEnd: number;
  GPLead: number;
  InInvt_Date: number;
  InInvt_NonDilutive: number;
  InInvt_Dilutive: number;
  InInvt_FDO: number;
  InvtCD_HoldPeriod: number;
  InvtCD_NonDilutive: number;
  InvtCD_Dilutive: number;
  InvtCD_FDO: number;
  InvtCD_Reserved: number;
  Current_Moic: number;
  Current_UnrealNonDil: number;
  Current_UnrealDil: number;
  Current_GainLoss: number;
  Current_realized: number;
  CriterionP1: number;
  CriterionP3: number;
  Exp_FinalReturn_MOIC: number;
  Exp_FinalReturn_Tier: number;
  Exp_FinalReturn_Delta: number;
  Exp_FinalReturn_Final: number;
  Exp_FinalReturn_Excess: number;
  "Exp_FinalReturn_TC Rating": number;
  LastValChange_QQ: number;
  LastValChange_YYYY: number;
  LastValChange_Nature: number;
  RecentChanges_LastChanges: number;
  EntValue_Current: number;
  EntValue_LastFY: number;
  "EntValue_LastFY-1": number;
  "EntValue_LastFY-2": number;
  "EntValue_LastFY-3": number;
  EntValue_Initial: number;
  EqVal_Current: number;
  EqVal_LastFY: number;
  "EqVal_LastFY-1": number;
  "EqVal_LastFY-2": number;
  "EqVal_LastFY-3": number;
  EqVal_Initial: number;
  BS_Runway: number;
  TopLine_CurrentFY: number;
  TopLine_Metric: number;
  "TopLine_CurrentFY-YTD": number;
  TopLine_YTDMonths: number;
  "TopLine_Expected-Budget": number;
  TopLine_CurrentFYE: number;
  TopLine_NextFYE: number;
  TopLine_LastFY: number;
  "TopLine_LastFY-1": number;
  "TopLine_LastFY-2": number;
  "TopLine_LastFY-3": number;
  TopLine_Initial: number;
  Profitability_Metric: number;
  "Profitability_CurrentFY-YTD": number;
  Profitability_CurrentFYE: number;
  Profitability_NextFYE: number;
  Profitability_LastFY: number;
  "Profitability_LastFY-1": number;
  "Profitability_LastFY-2": number;
  "Profitability_LastFY-3": number;
  Profitability_Initial: number;
  EmpCnt_CurrentFYE: number;
  EmpCnt_NextFYE: number;
  EmpCnt_CurrentFY: number;
  EmpCnt_LastFY: number;
  EmpCnt_LastFY01: number;
  EmpCnt_LastFY02: number;
  EmpCnt_LastFY03: number;
  EmpCnt_Initial: number;
  Textboxes_Valuation: number;
  Textboxes_Overview: number;
  "Textboxes_Milestones-ST": number;
  "Textboxes_Outlook-LT": number;
  "InvtHistory_Currency-1": number;
  ReportingDateKey: string;
  Valuation_Multiple: number;
  EqVal_Current_FYE: number;
  EqVal_Next_FYE: number;
  Net_Debt_Cash_Current_FY_YTD: number;
  Net_Debt_Cash_Current_FYE: number;
  Net_Debt_Cash_Next_FYE: number;
  Net_Debt_Cash_Last_FY: number;
  "Net_Debt_Cash_Last_FY-1": number;
  "Net_Debt_Cash_Last_FY-2": number;
  "Net_Debt_Cash_Last_FY-3": number;
  Net_Debt_Cash_Initial: number;
  portfolio_investedbyGP: string;
  portfolio_finalsizeRound: string;
  portfolio_noOfEmployee: number;
  portfolio_eqval: string;
  portfolio_entval: string;
  portfolio_Net_Debt_Cash: string;
  portfolio_series: string;
  portfolio_postmoneyval: string;
  portfolio_revenue: string;
  portfolio_ebitda: string;
  LP_name: string;
}

export const fieldNames: { [key in keyof PortfolioReviewReport]: string } = {
  Reporting_Date: "Reporting Date",
  Fund_Name: "Fund Name",
  portfolio_companyName: "Company Name",
  company_ID: "Company ID",
  fund_ID: "Fund ID",
  Textboxes_QtrUpdate: "Qtr Update",
  portfolio_fiscalYearEnd: "Fiscal Year End",
  GPLead: "GP Lead",
  InInvt_Date: "In Invt Date",
  InInvt_NonDilutive: "In Invt Non Dilutive",
  InInvt_Dilutive: "In Invt Dilutive",
  InInvt_FDO: "In Invt FDO",
  InvtCD_HoldPeriod: "Invt CD Hold Period",
  InvtCD_NonDilutive: "Invt CD Non Dilutive",
  InvtCD_Dilutive: "Invt CD Dilutive",
  InvtCD_FDO: "Invt CD FDO",
  InvtCD_Reserved: "Invt CD Reserved",
  Current_Moic: "Current Moic",
  Current_UnrealNonDil: "Current Unreal Non Dil",
  Current_UnrealDil: "Current Unreal Dil",
  Current_GainLoss: "Current Gain Loss",
  Current_realized: "Current Realized",
  CriterionP1: "Criterion P1",
  CriterionP3: "Criterion P3",
  Exp_FinalReturn_MOIC: "Exp Final Return MOIC",
  Exp_FinalReturn_Tier: "Exp Final Return Tier",
  Exp_FinalReturn_Delta: "Exp Final Return Delta",
  Exp_FinalReturn_Final: "Exp Final Return Final",
  Exp_FinalReturn_Excess: "Exp Final Return Excess",
  "Exp_FinalReturn_TC Rating": "Exp Final Return TC Rating",
  LastValChange_QQ: "Last Val Change QQ",
  LastValChange_YYYY: "Last Val Change YYYY",
  LastValChange_Nature: "Last Val Change Nature",
  RecentChanges_LastChanges: "Recent Changes Last Changes",
  EntValue_Current: "Ent Value Current",
  EntValue_LastFY: "Ent Value Last FY",
  "EntValue_LastFY-1": "Ent Value Last FY-1",
  "EntValue_LastFY-2": "Ent Value Last FY-2",
  "EntValue_LastFY-3": "Ent Value Last FY-3",
  EntValue_Initial: "Ent Value Initial",
  EqVal_Current: "Eq Val Current",
  EqVal_LastFY: "Eq Val Last FY",
  "EqVal_LastFY-1": "Eq Val Last FY-1",
  "EqVal_LastFY-2": "Eq Val Last FY-2",
  "EqVal_LastFY-3": "Eq Val Last FY-3",
  EqVal_Initial: "Eq Val Initial",
  BS_Runway: "BS Runway",
  TopLine_CurrentFY: "Top Line Current FY",
  TopLine_Metric: "Top Line Metric",
  "TopLine_CurrentFY-YTD": "Top Line Current FY-YTD",
  TopLine_YTDMonths: "Top Line YTD Months",
  "TopLine_Expected-Budget": "Top Line Expected-Budget",
  TopLine_CurrentFYE: "Top Line Current FYE",
  TopLine_NextFYE: "Top Line Next FYE",
  TopLine_LastFY: "Top Line Last FY",
  "TopLine_LastFY-1": "Top Line Last FY-1",
  "TopLine_LastFY-2": "Top Line Last FY-2",
  "TopLine_LastFY-3": "Top Line Last FY-3",
  TopLine_Initial: "Top Line Initial",
  Profitability_Metric: "Profitability Metric",
  "Profitability_CurrentFY-YTD": "Profitability Current FY-YTD",
  Profitability_CurrentFYE: "Profitability Current FYE",
  Profitability_NextFYE: "Profitability Next FYE",
  Profitability_LastFY: "Profitability Last FY",
  "Profitability_LastFY-1": "Profitability Last FY-1",
  "Profitability_LastFY-2": "Profitability Last FY-2",
  "Profitability_LastFY-3": "Profitability Last FY-3",
  Profitability_Initial: "Profitability Initial",
  EmpCnt_CurrentFYE: "Emp Cnt Current FYE",
  EmpCnt_NextFYE: "Emp Cnt Next FYE",
  EmpCnt_CurrentFY: "Emp Cnt Current FY",
  EmpCnt_LastFY: "Emp Cnt Last FY",
  EmpCnt_LastFY01: "Emp Cnt Last FY-1",
  EmpCnt_LastFY02: "Emp Cnt Last FY-2",
  EmpCnt_LastFY03: "Emp Cnt Last FY-3",
  EmpCnt_Initial: "Emp Cnt Initial",
  Textboxes_Valuation: "Textboxes Valuation",
  Textboxes_Overview: "Textboxes Overview",
  "Textboxes_Milestones-ST": "Textboxes Milestones-ST",
  "Textboxes_Outlook-LT": "Textboxes Outlook-LT",
  "InvtHistory_Currency-1": "Invt History Currency-1",
  ReportingDateKey: "Reporting Date Key",
  Valuation_Multiple: "Valuation Multiple",
  EqVal_Current_FYE: "Eq Val Current FYE",
  EqVal_Next_FYE: "Eq Val Next FYE",
  Net_Debt_Cash_Current_FY_YTD: "Net Debt Cash Current FY-YTD",
  Net_Debt_Cash_Current_FYE: "Net Debt Cash Current FYE",
  Net_Debt_Cash_Next_FYE: "Net Debt Cash Next FYE",
  Net_Debt_Cash_Last_FY: "Net Debt Cash Last FY",
  "Net_Debt_Cash_Last_FY-1": "Net Debt Cash Last FY-1",
  "Net_Debt_Cash_Last_FY-2": "Net Debt Cash Last FY-2",
  "Net_Debt_Cash_Last_FY-3": "Net Debt Cash Last FY-3",
  Net_Debt_Cash_Initial: "Net Debt Cash Initial",
  portfolio_investedbyGP: "Invested by GP",
  portfolio_finalsizeRound: "Final Size Round",
  portfolio_noOfEmployee: "No Of Employee",
  portfolio_eqval: "Eq Val",
  portfolio_entval: "Ent Val",
  portfolio_Net_Debt_Cash: "Net Debt Cash",
  portfolio_series: "Series",
  portfolio_postmoneyval: "Post Money Val",
  portfolio_revenue: "Revenue",
  portfolio_ebitda: "Ebitda",
  LP_name: "LP Name",
};

export const orderArray: (keyof PortfolioReviewReport)[] = [
  "Reporting_Date",
  "portfolio_companyName",
  "Textboxes_Valuation",
  "LastValChange_QQ",
  "LastValChange_YYYY",
  "LastValChange_Nature",
  "BS_Runway",
  "Fund_Name",
  "company_ID",
  "fund_ID",
  "Textboxes_QtrUpdate",
  "portfolio_fiscalYearEnd",
  "GPLead",
  "InInvt_Date",
  "InInvt_NonDilutive",
  "InInvt_Dilutive",
  "InInvt_FDO",
  "InvtCD_HoldPeriod",
  "InvtCD_NonDilutive",
  "InvtCD_Dilutive",
  "InvtCD_FDO",
  "InvtCD_Reserved",
  "Current_Moic",
  "Current_UnrealNonDil",
  "Current_UnrealDil",
  "Current_GainLoss",
  "Current_realized",
  "CriterionP1",
  "CriterionP3",
  "Exp_FinalReturn_MOIC",
  "Exp_FinalReturn_Tier",
  "Exp_FinalReturn_Delta",
  "Exp_FinalReturn_Final",
  "Exp_FinalReturn_Excess",
  "Exp_FinalReturn_TC Rating",
  "LastValChange_QQ",
  "LastValChange_YYYY",
  "LastValChange_Nature",
  "RecentChanges_LastChanges",
  "EntValue_Current",
  "EntValue_LastFY",
  "EntValue_LastFY-1",
  "EntValue_LastFY-2",
  "EntValue_LastFY-3",
  "EntValue_Initial",
  "EqVal_Current",
  "EqVal_LastFY",
  "EqVal_LastFY-1",
  "EqVal_LastFY-2",
  "EqVal_LastFY-3",
  "EqVal_Initial",
  "BS_Runway",
  "TopLine_CurrentFY",
  "TopLine_Metric",
  "TopLine_CurrentFY-YTD",
  "TopLine_YTDMonths",
  "TopLine_Expected-Budget",
  "TopLine_CurrentFYE",
  "TopLine_NextFYE",
  "TopLine_LastFY",
  "TopLine_LastFY-1",
  "TopLine_LastFY-2",
  "TopLine_LastFY-3",
  "TopLine_Initial",
  "Profitability_Metric",
  "Profitability_CurrentFY-YTD",
  "Profitability_CurrentFYE",
  "Profitability_NextFYE",
  "Profitability_LastFY",
  "Profitability_LastFY-1",
  "Profitability_LastFY-2",
  "Profitability_LastFY-3",
  "Profitability_Initial",
  "EmpCnt_CurrentFYE",
  "EmpCnt_NextFYE",
  "EmpCnt_CurrentFY",
  "EmpCnt_LastFY",
  "EmpCnt_LastFY01",
  "EmpCnt_LastFY02",
  "EmpCnt_LastFY03",
  "EmpCnt_Initial",
  "Textboxes_Valuation",
  "Textboxes_Overview",
  "Textboxes_Milestones-ST",
  "Textboxes_Outlook-LT",
  "InvtHistory_Currency-1",
  "ReportingDateKey",
  "EqVal_Current_FYE",
  "EqVal_Next_FYE",
  "Net_Debt_Cash_Current_FY_YTD",
  "Net_Debt_Cash_Current_FYE",
  "Net_Debt_Cash_Next_FYE",
  "Net_Debt_Cash_Last_FY",
  "Net_Debt_Cash_Last_FY-1",
  "Net_Debt_Cash_Last_FY-2",
  "Net_Debt_Cash_Last_FY-3",
  "Net_Debt_Cash_Initial",
  "portfolio_investedbyGP",
  "portfolio_finalsizeRound",
  "portfolio_noOfEmployee",
  "portfolio_eqval",
  "portfolio_entval",
  "portfolio_Net_Debt_Cash",
  "portfolio_series",
  "portfolio_postmoneyval",
  "portfolio_revenue",
  "portfolio_ebitda",
  "LP_name",
];
