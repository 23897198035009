import {
  Divider as MuiDivider,
  Breadcrumbs as MuiBreadcrumbs,
  Typography,
  Link
} from "@mui/material";
import React from "react";
import { NavLink } from "react-router-dom";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import { Helmet } from "react-helmet-async";
import AddDistributionFileForm from "./addDistributionFileForm";
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

function AIDistributionCall() {
  return (
    <div style={{ alignContent: "center", alignSelf: "centers" }}>
      <Helmet title="Distribution" />
      <Typography variant="h3" gutterBottom display="inline">
        Add Distribution Report
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Dashboard
        </Link>
        <Link component={NavLink} to="/workFlow">
          WorkFlow
        </Link>
        <Typography>Add Distribution Report</Typography>
      </Breadcrumbs>
      <Divider my={6} />
      <AddDistributionFileForm />
    </div>
  );
}

export default AIDistributionCall;
