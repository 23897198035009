import React, { ReactElement, useEffect } from "react";
import { useState } from "react";
import DashboardLayout from "../../../../layouts/Dashboard";
import { Helmet } from "react-helmet-async";
import axiosInstance from "../../../../utils/axios/axios.instance";
import {
  Button,
  Link,
  Paper,
  Typography,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { IFundStatic } from "../../../../interfaces/fundStatic.interface";
import {
  fieldNames,
  orderArray,
  portfolioReview,
} from "../../../../interfaces/portfolioReview.interface";
import { toast } from "react-toastify";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import { NavLink } from "react-router-dom";
import EditableTable from "../../../../components/Workflow/portfolioAllReviewTable";
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Divider = styled(MuiDivider)(spacing);

function PortfolioReview() {
  const [date, setDate] = useState<Date | null>(new Date());
  const [selectedFundName, setSelectedFundName] = useState("");
  const [isPreviousData, setIsPreviosuData] = useState(false);
  const [selectedportfolioReviewDate, setselectedportfolioReviewDate] =
    useState("");
  const [isSelected, setIsSelected] = useState(false);
  const [fieldData, setFieldData] = useState({});
  const [message, setMessage] = useState("");
  const [allData, setAllData] = useState<[]>([]);
  const [selectedPortfolioID, setSelectedPortfolioID] = useState("");
  const [selectedFundId, setSelectedFundId] = useState("");
  const [fundNames, setFundNames] = useState<Array<{ [key: string]: string }>>(
    []
  );
  function createFieldData(responseData: portfolioReview): {
    [key: string]: {
      type: string | Date;
      defaultValue: string | undefined | Date | File | Boolean | Number;
      name: string | undefined;
    };
  } {
    const excludedFields = [
      "_id",
      "fund_ID",
      "company_ID",
      "ReportingDateKey",
      "ReportingDateString",
    ];
    const fieldData: {
      [key: string]: {
        type: string | Date;
        defaultValue: string | undefined | Date | File | Boolean | Number;
        name: string | undefined;
      };
    } = {};
    orderArray.forEach((key) => {
      if (excludedFields.includes(key) || !(key in responseData)) {
        return;
      }
      let value;
      if (key === "Fund_Name") {
        value = responseData[key as keyof portfolioReview];
      } else {
        value = responseData[key as keyof portfolioReview];
      }

      // const value = responseData[key as keyof portfolioReview];
      let type: string;

      if (typeof value === "string") {
        type = "text";
      } else if (typeof value === "boolean") {
        type = "checkbox";
      } else if (typeof value === "number") {
        type = "number";
      } else if (Array.isArray(value)) {
        type = "array";
      } else {
        type = "string";
      }

      fieldData[key] = {
        type,
        defaultValue: value,
        name: fieldNames?.[key as keyof portfolioReview],
      };
    });
    return fieldData;
  }
  const fetchData = async (id: string) => {
    try {
      setFieldData({});
      const response = await axiosInstance.get(
        `/portfolio-review-table/${id}/${selectedFundId}`
      );

      if (response.data === "error") {
        setMessage("Error wh`ile fetching Data.");
      } else {
        if (response.data.data.length === 0) {
          setMessage("There is no data with the given Date");
        } else {
          setMessage("");
          const responseData = response.data.data;

          setAllData(responseData);
          // let temp = { ...responseData[0] };
          //   temp.Reporting_Date = id;
          //   temp.Fund_Name = selectedFundName;
          //   temp.Company_Name = selectedPortfolioName;
          //   temp.company_ID = selectedPortfolioID;
          //   temp.fund_ID = selectedFundId;
          // setFieldData(createFieldData(temp));
        }
        setIsSelected(true);
      }
    } catch (err) {
      setMessage("Error while fetching Data.");
      console.error(err);
    }
  };
  function formatDate(dateString: Date) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // +1 because getMonth() returns 0-11
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }

  const getFundNames = async () => {
    try {
      const response = await axiosInstance.get("/fund-names");
      const responseData = response.data.data;
      responseData.sort(
        (a: { Fund_Name: string }, b: { Fund_Name: string }) => {
          return a.Fund_Name.toLowerCase().localeCompare(
            b.Fund_Name.toLowerCase()
          );
        }
      );
      const fundNames = responseData.map((fund: IFundStatic) => {
        return { [fund.Fund_Name]: fund.fund_ID };
      });
      setFundNames(fundNames);
      // getPortfolioNames(Object.values(fundNames[0])[0] as string);
      setSelectedFundName(Object.keys(fundNames[0])[0] as string);
      setSelectedFundId(Object.values(fundNames[0])[0] as string);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getFundNames();
    // getPortfolioNames();
  }, []);

  useEffect(() => {
    setselectedportfolioReviewDate(formatDate(new Date()));
  }, []);

  return (
    <React.Fragment>
      <Helmet title="Portfolio Review" />
      <Typography variant="h3" gutterBottom display="inline">
        Portfolio Review Form
      </Typography>
      <div
        style={{
          marginTop: "0px",
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
        }}
      >
        <Breadcrumbs aria-label="Breadcrumb" mt={2}>
          <Link component={NavLink} to="/">
            Dashboard
          </Link>
          <Link component={NavLink} to="/workFlow">
            WorkFlow
          </Link>
          <Typography>Portfolio Review Update</Typography>
        </Breadcrumbs>
      </div>
      <Divider my={6} />
      <Paper
        style={{
          padding: "10px",
        }}
      >
        <div>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              alignItems: "center",
              padding: "0 15%",
            }}
          >
            <div style={{ flex: "1 1 200px", minWidth: "200px" }}>
              <label
                style={{
                  marginRight: "10px",
                  marginTop: "10px",
                  fontWeight: "bold",
                }}
              >
                Select Fund :
              </label>
              <select
                onChange={(e) => {
                  setSelectedFundName(
                    Object.keys(fundNames[e.target.selectedIndex]).toString()
                  );
                  setSelectedFundId(e.target.value);
                  // getPortfolioNames(e.target.value);
                }}
                style={{
                  backgroundColor: "#f2f2f2", // Background color
                  color: "#333", // Text color
                  padding: "10px", // Padding
                  border: "1px solid #ccc", // Border
                  borderRadius: "5px", // Border radius
                  outline: "none", // Remove outline when selected
                  boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)", // Box shadow
                  width: "200px", // Width
                  cursor: "pointer", // Cursor style
                  height: "52px",
                  marginTop: "20px",
                }}
              >
                {fundNames.map((fund, index) => {
                  const fundName = Object.keys(fund)[0];
                  const fundID = fund[fundName];
                  return (
                    <option key={index} value={fundID}>
                      {fundName}
                    </option>
                  );
                })}
              </select>
            </div>
            <div
              style={{
                flex: "1 1 200px",
                minWidth: "200px",
                marginLeft: "10px",
                marginTop: "20px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <label
                style={{
                  marginRight: "10px",

                  fontWeight: "bold",
                }}
              >
                Select a date :
              </label>

              <DatePicker
                format="dd-MM-yyyy"
                label="Transaction Date"
                value={date}
                onChange={(newValue) => {
                  if (newValue !== null) {
                    // fetchData(formatDate(newValue), selectedFundName);
                    setselectedportfolioReviewDate(formatDate(newValue));
                    setDate(newValue);
                  }
                }}
              />
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              style={{
                marginLeft: "10px",
                marginTop: "10px",
                backgroundColor: "#007bff",
                borderRadius: "18px",
              }}
              variant="contained"
              color="primary"
              onClick={() => fetchData(selectedportfolioReviewDate)}
            >
              Get Data
            </Button>
          </div>
          {allData.length > 0 && <EditableTable data={allData} />}
          {/* {message !== "" && <>{message}</>} */}
        </div>
      </Paper>
    </React.Fragment>
  );
}
PortfolioReview.getLayout = function getLayout(page: ReactElement) {
  return <DashboardLayout>{page}</DashboardLayout>;
};
export default PortfolioReview;
