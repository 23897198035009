import React, {
  useState,
  ChangeEvent,
  FormEvent,
  CSSProperties,
  useEffect,
} from "react";
import { Grid } from "@mui/material";
import {
  DynamicFormProps,
  FormData,
} from "../../interfaces/addDataForm.interface";
import { keyDisplayNames } from "../UpdateScript/HelpingFunctions/updateScript";
import FormField from "../add_updateForm/FormField";
import FormTableRow from "../add_updateForm/FormTableRow";
import { KeyNames, restrictedKeys } from "../HelperFunctions/addDataForm";

const DynamicForm: React.FC<DynamicFormProps> = ({
  fieldData,
  changedata,
  staticData,
  newData,
  reportingDate,
  orderKeys,
}) => {
  const [image, setImage] = useState("");
  const fundStaticKeysLookup: { [key: string]: number } = {};
  orderKeys.forEach((item) => {
    const key = Object.keys(item)[0];
    fundStaticKeysLookup[key] = item[key];
  });
  const orderKeysName = orderKeys.map((item) => Object.keys(item)[0]);
  const isKey2 = orderKeysName.some(
    (key) => fundStaticKeysLookup[key] === 2 && !restrictedKeys.includes(key)
  );

  useEffect(() => {
    if (staticData) {
      const updatedFormState: FormData = {};
      setImageError(false);
      Object.keys(fieldData).forEach((fieldName) => {
        if (fieldName === "Reporting_Date") {
          const [day, month, year] =
            fieldData[fieldName].defaultValue.split("-");
          const formattedDate = `${year}-${month}-${day}`;
          updatedFormState[fieldName] = formattedDate;
        } else {
          updatedFormState[fieldName] = fieldData[fieldName].defaultValue;
        }
      });
      setFormData(updatedFormState);
    } else {
      const updatedFormState: FormData = {};
      Object.keys(fieldData).forEach((fieldName) => {
        if (fieldName === "Reporting_Date") {
          const [day, month, year] = reportingDate.split("-");
          const formattedDate = `${day}-${month}-${year}`;
          updatedFormState[fieldName] = formattedDate;
        } else {
          updatedFormState[fieldName] = "";
        }
      });
      setFormData(updatedFormState);
    }
  }, [fieldData, reportingDate, staticData]);
  const initialFormState: FormData = {};
  if (staticData) {
    Object.keys(fieldData).forEach((fieldName) => {
      initialFormState[fieldName] = fieldData[fieldName].defaultValue || "";
    });
  } else {
    Object.keys(fieldData).forEach((fieldName) => {
      initialFormState[fieldName] = "";
    });
  }
  const handleFileChange = (event: any) => {
    const file = event.target.files[0]; // Get the selected file
    if (file) {
      const objectURL = URL.createObjectURL(file);
      setImage(objectURL);
    }
    if (file) {
      setFormData({
        ...formData,
        [event.target.name]: file, // Store the File object
      });
    }
  };
  const [imageError, setImageError] = useState(false);

  // Function to handle image load error
  const handleImageError = () => {
    setImageError(true);
  };

  const [formData, setFormData] = useState<FormData>(initialFormState);
  const [changedFields, setChangedFields] = useState<FormData>({});
  const styles: { [key: string]: CSSProperties } = {
    label: {
      fontWeight: "bold",
      paddingBottom: "5px",
      paddingLeft: "10px", // Add padding for aesthetic spacing
      width: "100%", // Align the text to the right within the label cell
      verticalAlign: "top",
    },
    label2: {
      fontWeight: "bold",
      padding: "5px 20px",
    },
    input: {
      verticalAlign: "text-top",
      padding: "10px",
      border: "1px solid #ccc",
      width: "200px",
      borderRadius: "4px", // Make input take full width of its cell
    },
    buttonContainer: {
      textAlign: "center",
      marginTop: "20px", // Space above the button
    },
    button: {
      padding: "12px 24px",
      backgroundColor: "#007bff",
      color: "#fff",
      border: "none",
      borderRadius: "18px",
      cursor: "pointer",
    },
    button2: {
      marginLeft: "20px",
      padding: "12px 24px",
      backgroundColor: "#007bff",
      color: "#fff",
      border: "none",
      borderRadius: "18px",
      cursor: "pointer",
    },
  };
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    let { name, value } = e.target;
    if (e.target.type === "checkbox") {
      value = e.target.checked ? "on" : "off";
    }
    setFormData({
      ...formData,
      [name]: value,
    });
    setChangedFields({
      ...changedFields,
      [name]: value,
    });
  };
  const handleSubmitForm = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Call the onSubmit prop with the formData
    changedata(formData, "add");
    setChangedFields({});
  };
  const handleSubmitForm2 = () => {
    if (formData["portfolio_companyLogo"] != null) {
      const data = {
        portfolio_companyLogo: formData["portfolio_companyLogo"],
        ...changedFields,
      };
      changedata(data, "update");
      setChangedFields({});
    } else if (formData["fund_logo"] != null) {
      const data = {
        fund_logo: formData["fund_logo"],
        ...changedFields,
      };
      changedata(data, "update");
      setChangedFields({});
    } else {
      changedata(changedFields, "update");
      setChangedFields({});
    }
  };
  const form = React.createRef<HTMLFormElement>();

  if (!staticData) {
    return (
      <div
        style={{
          marginTop: "25px",
        }}
      >
        <form onSubmit={handleSubmitForm} ref={form}>
          <table>
            <tbody>
              {Object.keys(fieldData)
                .filter((key) => !restrictedKeys.includes(key))
                .map((fieldName, index, array) => (
                  <FormTableRow
                    key={fieldName}
                    fieldName={fieldName}
                    fieldData={fieldData}
                    formData={formData}
                    handleChange={handleChange}
                    handleFileChange={handleFileChange}
                    handleImageError={handleImageError}
                    imageError={imageError}
                    image={image}
                    styles={styles}
                    KeyNames={KeyNames}
                    index={index}
                    array={array}
                  />
                ))}
            </tbody>
          </table>
          <div style={styles.buttonContainer}>
            <button type="submit" style={styles.button}>
              {newData ? "Add" : "Update"}
            </button>
          </div>
        </form>
      </div>
    );
  } else {
    return (
      <div
        style={{
          marginTop: "25px",
        }}
      >
        <form onSubmit={handleSubmitForm} ref={form}>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            style={{
              border: "3px solid #36454F",
              marginTop: "10px",
              padding: "10px",
            }}
          >
            {orderKeysName
              .filter((key) => !restrictedKeys.includes(key))
              // eslint-disable-next-line array-callback-return
              .map((fieldName) => {
                if (fundStaticKeysLookup[fieldName] === 1) {
                  return (
                    <FormField
                      key={fieldName}
                      fieldName={fieldName}
                      fieldData={fieldData}
                      formData={formData}
                      handleChange={handleChange}
                      handleFileChange={handleFileChange}
                      handleImageError={handleImageError}
                      imageError={imageError}
                      styles={styles}
                    />
                  );
                }
              })}
          </Grid>
          {isKey2 && (
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              style={{
                border: "3px solid #483D8B",
                marginTop: "10px",
                padding: "10px",
              }}
            >
              {keyDisplayNames != null &&
                orderKeysName
                  .filter((key) => !restrictedKeys.includes(key))
                  // eslint-disable-next-line array-callback-return
                  .map((fieldName) => {
                    if (fundStaticKeysLookup[fieldName] === 2) {
                      return (
                        <FormField
                          key={fieldName}
                          fieldName={fieldName}
                          fieldData={fieldData}
                          formData={formData}
                          handleChange={handleChange}
                          handleFileChange={handleFileChange}
                          handleImageError={handleImageError}
                          imageError={imageError}
                          styles={styles}
                        />
                      );
                    }
                  })}
            </Grid>
          )}
        </form>
        <div style={styles.buttonContainer}>
          <button
            type="button"
            style={styles.button2}
            onClick={handleSubmitForm2}
          >
            Update
          </button>
        </div>
      </div>
    );
  }
};

export default DynamicForm;
