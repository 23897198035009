import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";
import {
  CardContent,
  Grid,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import axiosInstance from "../../../../utils/axios/axios.instance";
import { IESGReport } from "../../../../interfaces/esg.interface";
import ESGStatic from "../../../../components/AddData/ESGStatic";
const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);

function AddESG() {
  useEffect(() => {
    document.title = "Add Data";
    getESGNames();
    // getPortfolioNames();
  }, []);
  const [ESGNames, setESGNames] = useState<Array<{ [key: string]: string }>>(
    []
  );
  const [selectedESGID, setSelectedESGID] = useState("C000001");
  const getESGNames = async () => {
    try {
      const response = await axiosInstance.get("/esg_report");
      const responseData = response.data.data;
      const ESGNames = responseData.map((ESG: IESGReport) => {
        return { [ESG.Main_Company_Name]: ESG.company_ID };
      });
      setSelectedESGID(Object.values(ESGNames[0])[0] as string);
      setESGNames(ESGNames);
      if (ESGID.current) {
        ESGID.current.value = Object.values(ESGNames[0])[0] as string;
      }
    } catch (error) {
      console.error(error);
    }
  };
  const ESGID = useRef<HTMLSelectElement>(null);
  return (
    <React.Fragment>
      <Helmet title="Blank" />
      <Typography variant="h3" gutterBottom display="inline">
        ESG Reports
      </Typography>
      <Divider my={6} />
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card mb={6}>
            <CardContent>
              <div style={{ paddingTop: "20px" }}>
                <div style={{ marginRight: "20px" }}>ESG ID:</div>
                <select
                  ref={ESGID}
                  onChange={() => {
                    setSelectedESGID(ESGID.current?.value ?? "");
                  }}
                  style={{
                    backgroundColor: "#f2f2f2", // Background color
                    color: "#333", // Text color
                    padding: "10px", // Padding
                    border: "1px solid #ccc", // Border
                    borderRadius: "5px", // Border radius
                    outline: "none", // Remove outline when selected
                    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)", // Box shadow
                    width: "250px", // Width
                    cursor: "pointer", // Cursor style
                  }}
                >
                  {ESGNames.map((ESG, index) => {
                    const ESGName = Object.keys(ESG)[0];
                    const ESGID = ESG[ESGName];
                    return (
                      <option key={index} value={ESGID}>
                        {ESGName}
                      </option>
                    );
                  })}
                </select>

                <div>
                  <ESGStatic id={selectedESGID} />
                </div>
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default AddESG;
