export const portfolioReviewKeyOrder = [
    "Reporting_Date",
    "Textboxes_Strategy",
    "Textboxes_Outlook",
    "Textboxes_QtrlyUpdate",
    "Textboxes_FundPipe",
    "Textboxes_Co-InvestPipe",
    "Textboxes_Exits"
];

export const portfolioReviewconstKey = [
    "Reporting_Date",
    "portfolio_companyName",
    "Fund_Name",
    "fund_ID",
    "company_ID",
    "created_At",
];

export const PortfolioReviewKeyNames: { [key: string]: string } = {
    Reporting_Date: "Reporting Date",
    Textboxes_Strategy: "Strategy",
    Textboxes_Outlook: "Outlook",
    Textboxes_QtrlyUpdate: "Qtrly Update",
    Textboxes_FundPipe: "Fund Pipe",
    "Textboxes_Co-InvestPipe": "Co-Invest Pipe",
    Textboxes_Exits: "Exits",
};


export const portfolioReviewDataFields = [
    {
        Reporting_Date: "",
        Textboxes_Strategy: "",
        Textboxes_Outlook: "",
        Textboxes_QtrlyUpdate: "",
        Textboxes_FundPipe: "",
        "Textboxes_Co-InvestPipe": "",
        Textboxes_Exits: "",
    }
];