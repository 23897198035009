import React from "react";

function Presentation() {
  return (
    <React.Fragment>
      <div style={{ marginTop: "15%" }}>
        <h1 style={{ marginLeft: "40%" }}>Welcome to AltQ</h1>
      </div>
    </React.Fragment>
  );
}

export default Presentation;
