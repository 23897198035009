import React, { ReactElement } from "react";
import { useState } from "react";
import DashboardLayout from "../../../../layouts/Dashboard";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Helmet } from "react-helmet-async";
import axiosInstance from "../../../../utils/axios/axios.instance";
import {
  FOFReports,
  fieldNames,
  orderArray,
} from "../../../../interfaces/fofReport.interface";
import { Box, Button, Paper } from "@mui/material";
import { useNavigate } from "react-router-dom";
import DynamicForm3 from "../../../../components/Workflow/workFlow_dataUpdateForm";
import { DatePicker } from "@mui/x-date-pickers";
import { toast } from "react-toastify";

function FoFQtr() {
  const [date, setDate] = useState<Date | null>(new Date());
  const [isPrevviousData, setIsPreviousData] = useState(false);
  const [selectedFoFDate, setSelectedFoFDate] = useState("");
  const [isSelected, setIsSelected] = useState(false);
  const [fieldData, setFieldData] = useState({});
  const [fieldData2, setFieldData2] = useState({});

  const [message, setMessage] = useState("");
  function createFieldData(responseData: FOFReports): {
    [key: string]: {
      type: string | number | Date;
      defaultValue: string | number | undefined | Date | File | Boolean;
      name: string | undefined;
    };
  } {
    const excludedFields = ["_id", "FoFkey"];
    const fieldData: {
      [key: string]: {
        type: string | number | Date;
        defaultValue: string | number | undefined | Date | File | Boolean;
        name: string | undefined;
      };
    } = {};

    orderArray.forEach((key) => {
      if (excludedFields.includes(key) || !(key in responseData)) {
        return;
      }

      let value;

      // if (key === "Reporting_Date") {
      //   value = responseData[key as keyof FOFReports]
      //     .toString()
      //     .split("T")[0]
      //     .split("-")
      //     .reverse()
      //     .join("-");
      // } else {
      value = responseData[key as keyof FOFReports];
      // }
      let type: string;
      if (key === "fr_logo") {
        type = "file";
      } else if (typeof value === "string") {
        type = "text";
      } else if (typeof value === "boolean") {
        type = "checkbox";
      } else if (typeof value === "number") {
        type = "number";
      } else if (Array.isArray(value)) {
        type = "array";
      } else {
        type = typeof value;
      }

      fieldData[key] = {
        type,
        defaultValue: value,
        name: fieldNames?.[key as keyof FOFReports],
      };
    });
    return fieldData;
  }
  const fetchData = async (id: string) => {
    try {
      setFieldData({});
      const response = await axiosInstance.get(`/getFOFQtrByID/${id}`);
      const response2 = await axiosInstance.get(`/getFOFQtrActualByID/${id}`);
      if (response.data === "error") {
        setMessage("Error while fetching Data.");
      } else {
        if (response.data.data.length === 0) {
          setMessage("There is no data with the given Date");
        } else {
          const responseData = response.data.data[0];
          const responseData2 = response2.data.data[0];
          if (responseData2 !== undefined) {
            setIsPreviousData(true);
            setFieldData2(createFieldData(responseData2));
          } else {
            setIsPreviousData(false);
            setFieldData2(
              createFieldData({
                _id: "",
                Reporting_Date: id,
                LP_name: "",
                lp_vintage: 0,
                lp_aggregateCommitments: 0,
                lp_recallableDistributions: 0,
                lp_advances: 0,
                lp_total: 0,
                lp_called: 0,
                lp_uncalled: 0,
                lp_commitmentPortfolioInvt: 0,
                lp_distrobutionsToLP: 0,
                lp_navToLP: 0,
                lp_totalValue: 0,
                lp_navGP: 0,
                lp_totalNAV: 0,
                lp_fundGrossIRR: 0,
                lp_fundNetIRR: 0,
                lp_fundNetIRR_A: 0,
                lp_fundNetIRR_B: 0,
                lp_navIQ: 0,
                lp_navCDPQ: 0,
                lp_navIFSTQ: 0,
                lp_definitiveDistributions: 0,
                lp_dq_calls: 0,
                lp_dq_distributionsPaid: 0,
                lp_dq_drewCredit: 0,
                lp_dq_repayCredit: 0,
                lp_eq_calls: 0,
                lp_eq_distributionsPaid: 0,
                lp_eq_drewCredit: 0,
                lp_aq_calls: 0,
                lp_aq_distributionsPaid: 0,
                lp_aq_drewCredit: 0,
                lp_aq_repayCredit: 0,
                FoFkey: "",
              })
            );
          }
          setIsSelected(true);
          setFieldData(createFieldData(responseData));
        }
      }
    } catch (err) {
      setMessage("Error while fetching Data.");
      console.error(err);
    }
  };

  const navigate = useNavigate();

  const handleBack = () => {
    navigate("/workflow"); // Navigate to the /workflow page
  };

  const handleChangeData = async (formData: {
    [fieldName: string]: string | undefined | Date | File | Boolean;
  }) => {
    try {
      const form = new FormData();
      const formDataObject = formData;
      form.append("FoFkey", selectedFoFDate + ":TCFF2009");
      form.append("formData", JSON.stringify(formDataObject));

      if (isPrevviousData) {
        const response = await axiosInstance.post("/updateFOFByID", form, {
          headers: {
            "Content-Type": "multipart/form-data", // or you can omit this line
          },
        });
        if (response.data === "error") {
          toast.error("Error while updating Data.");
          setMessage("Error while updating Data.");
        } else {
          setFieldData({});
          toast.success("Data updated successfully");
          setMessage("Data updated successfully");
        }
      } else {
        const response = await axiosInstance.post("/add_FOFData", form, {
          headers: {
            "Content-Type": "multipart/form-data", // or you can omit this line
          },
        });
        if (response.data === "error") {
          toast.error("Error while updating Data.");
          setMessage("Error while updating Data.");
        } else {
          setFieldData({});
          toast.success("Data updated successfully");
          setMessage("Data updated successfully");
        }
      }
    } catch (err) {
      toast.error("Error while updating Data.");
      setMessage("Error while updating Data.");
      console.error(err);
    } finally {
      setTimeout(() => {
        fetchData(selectedFoFDate);
        setMessage("");
      }, 3000);
    }
  };
  function formatDate(dateString: Date) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  }
  return (
    <React.Fragment>
      <Helmet title="Add Reporting Data" />
      <Paper
        style={{
          padding: "10px",
        }}
      >
        <h1>FOF Quarterly Form</h1>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          padding="10px"
        >
          <div>
            <label
              style={{
                marginRight: "10px",
                marginTop: "10px",
                fontWeight: "bold",
              }}
            >
              Select a date :
            </label>
            <DatePicker
              format="dd-MM-yyyy"
              label="Reporting Date"
              value={date}
              onChange={(newValue) => {
                if (newValue !== null) {
                  fetchData(formatDate(newValue));
                  setSelectedFoFDate(formatDate(newValue));
                  setDate(newValue);
                }
              }}
            />
          </div>
          <Button
            startIcon={<ArrowBackIcon />}
            variant="contained"
            color="primary"
            onClick={handleBack}
            style={{
              backgroundColor: "#007bff",
              borderRadius: "18px",
            }}
          >
            Back
          </Button>
        </Box>
        {isSelected && (
          <DynamicForm3
            fieldData={fieldData}
            fieldData2={fieldData2}
            changedata={handleChangeData}
            staticData={false}
            date={selectedFoFDate}
            isUpdate={isPrevviousData}
          />
        )}
        {message !== "" && <>{message}</>}
      </Paper>
    </React.Fragment>
  );
}
FoFQtr.getLayout = function getLayout(page: ReactElement) {
  return <DashboardLayout>{page}</DashboardLayout>;
};
export default FoFQtr;
