export interface IESGReport {
  company_ID: string;
  Social_impact: string;
  CEO_image: string;
  Key_Achievement: string;
  Main_Company_Name: string;
  problem_addressed: string;
  CEO_text: string;
  CEO_name: string;
  fund_ID: string;
  ESGgoal_1_description: string;
  ESGgoal_1_checkbox: boolean;
  ESGgoal_2_description: string;
  ESGgoal_2_checkbox: boolean;
  ESGgoal_3_description: string;
  ESGgoal_3_checkbox: boolean;
  ESGgoal_4_description: string;
  ESGgoal_4_checkbox: boolean;
  ESGgoal_5_description: string;
  ESGgoal_5_checkbox: boolean;
  ESGgoal_6_description: string;
  ESGgoal_6_checkbox: boolean;
  ESGgoal_7_description: string;
  ESGgoal_7_checkbox: boolean;
  ESGgoal_8_description: string;
  ESGgoal_8_checkbox: boolean;
  ESGgoal_9_description: string;
  ESGgoal_9_checkbox: boolean;
  ESGgoal_10_description: string;
  ESGgoal_10_checkbox: boolean;
  ESGgoal_11_description: string;
  ESGgoal_11_checkbox: boolean;
  ESGgoal_12_description: string;
  ESGgoal_12_checkbox: boolean;
  ESGgoal_13_description: string;
  ESGgoal_13_checkbox: boolean;
  ESGgoal_14_description: string;
  ESGgoal_14_checkbox: boolean;
  ESGgoal_15_description: string;
  ESGgoal_15_checkbox: boolean;
  ESGgoal_16_description: string;
  ESGgoal_16_checkbox: boolean;
}

export const ESGfieldNames: { [key in keyof IESGReport]: string } = {
  Social_impact: "Social Impact",
  CEO_image: "CEO Image",
  CEO_name: "CEO Name",
  Key_Achievement: "Key Achievement",
  Main_Company_Name: "Main Company Name",
  problem_addressed: "Problem Addressed",
  CEO_text: "CEO Text",
  fund_ID: "Fund ID",
  company_ID: "Company ID",
  ESGgoal_1_description: "Goal 1 Description",
  ESGgoal_1_checkbox: "Goal 1",
  ESGgoal_2_description: "Goal 2 Description",
  ESGgoal_2_checkbox: "Goal 2",
  ESGgoal_3_description: "Goal 3 Description",
  ESGgoal_3_checkbox: "Goal 3",
  ESGgoal_4_description: "Goal 4 Description",
  ESGgoal_4_checkbox: "Goal 4",
  ESGgoal_5_description: "Goal 5 Description",
  ESGgoal_5_checkbox: "Goal 5",
  ESGgoal_6_description: "Goal 6 Description",
  ESGgoal_6_checkbox: "Goal 6",
  ESGgoal_7_description: "Goal 7 Description",
  ESGgoal_7_checkbox: "Goal 7",
  ESGgoal_8_description: "Goal 8 Description",
  ESGgoal_8_checkbox: "Goal 8",
  ESGgoal_9_description: "Goal 9 Description",
  ESGgoal_9_checkbox: "Goal 9",
  ESGgoal_10_description: "Goal 10 Description",
  ESGgoal_10_checkbox: "Goal 10",
  ESGgoal_11_description: "Goal 11 Description",
  ESGgoal_11_checkbox: "Goal 11",
  ESGgoal_12_description: "Goal 12 Description",
  ESGgoal_12_checkbox: "Goal 12",
  ESGgoal_13_description: "Goal 13 Description",
  ESGgoal_13_checkbox: "Goal 13",
  ESGgoal_14_description: "Goal 14 Description",
  ESGgoal_14_checkbox: "Goal 14",
  ESGgoal_15_description: "Goal 15 Description",
  ESGgoal_15_checkbox: "Goal 15",
  ESGgoal_16_description: "Goal 16 Description",
  ESGgoal_16_checkbox: "Goal 16",
};

export const ESGorderArray = [
  "Main_Company_Name",
  "problem_addressed",
  "Social_impact",
  "Key_Achievement",
  "CEO_text",
  "CEO_image",
  "CEO_name",
  "ESGgoal_1_checkbox",
  "ESGgoal_1_description",
  "ESGgoal_2_checkbox",
  "ESGgoal_2_description",
  "ESGgoal_3_checkbox",
  "ESGgoal_3_description",
  "ESGgoal_4_checkbox",
  "ESGgoal_4_description",
  "ESGgoal_5_checkbox",
  "ESGgoal_5_description",
  "ESGgoal_6_checkbox",
  "ESGgoal_6_description",
  "ESGgoal_7_checkbox",
  "ESGgoal_7_description",
  "ESGgoal_8_checkbox",
  "ESGgoal_8_description",
  "ESGgoal_9_checkbox",
  "ESGgoal_9_description",
  "ESGgoal_10_checkbox",
  "ESGgoal_10_description",
  "ESGgoal_11_checkbox",
  "ESGgoal_11_description",
  "ESGgoal_12_checkbox",
  "ESGgoal_12_description",
  "ESGgoal_13_checkbox",
  "ESGgoal_13_description",
  "ESGgoal_14_checkbox",
  "ESGgoal_14_description",
  "ESGgoal_15_checkbox",
  "ESGgoal_15_description",
  "ESGgoal_16_checkbox",
  "ESGgoal_16_description",
];
