import React, { ReactElement, useRef } from "react";
import { useEffect, useState } from "react";
import DashboardLayout from "../../../../layouts/Dashboard";
import { NavLink, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Helmet } from "react-helmet-async";
import axiosInstance from "../../../../utils/axios/axios.instance";
import {
  PortfolioReviewReport,
  fieldNames,
  orderArray,
} from "../../../../interfaces/portfoliReview";
import {
  Box,
  Button,
  Paper,
  Typography,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Link,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import DynamicForm3 from "../../../../components/Workflow/workFlow_dataUpdateForm";
import { IPortfolioQtr } from "../../../../interfaces/portfolioQtr.interface";
import { IFundStatic } from "../../../../interfaces/fundStatic.interface";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Divider = styled(MuiDivider)(spacing);

function Portfolio_Review_Table() {
  const [date, setDate] = useState<Date>(new Date());
  const [isSelected, setIsSelected] = useState(false);
  const [message, setMessage] = useState("");
  const [fieldData, setFieldData] = useState({});
  const fundID = useRef<HTMLSelectElement>(null);
  const portfolioID = useRef<HTMLSelectElement>(null);
  const [portfolioNames, setPortfolioNames] = useState<
    Array<{ [key: string]: string }>
  >([]);
  const [fundNames, setFundNames] = useState<Array<{ [key: string]: string }>>(
    []
  );
  const navigate = useNavigate();
  const [selectedPorfolioID, setSelectedPortfolioID] = useState("");
  const [selectedFundID, setSelectedFundID] = useState("");
  function createFieldData(responseData: PortfolioReviewReport): {
    [key: string]: {
      type: string | Date;
      defaultValue: string | Number;
      name: string | undefined;
    };
  } {
    const excludedFields = ["_id", "fund_ID", "company_ID", "distribution_ID"];
    const fieldData: {
      [key: string]: {
        type: string | Date;
        defaultValue: string | Number;
        name: string | undefined;
      };
    } = {};

    orderArray.forEach((key) => {
      if (excludedFields.includes(key) || !(key in responseData)) {
        return;
      }

      const value = responseData[key as keyof PortfolioReviewReport];
      let type: string;

      if (typeof value === "string") {
        type = "text";
      } else if (typeof value === "boolean") {
        type = "checkbox";
      } else if (typeof value === "number") {
        type = "number";
      } else if (Array.isArray(value)) {
        type = "array";
      } else {
        type = typeof value;
      }

      fieldData[key] = {
        type,
        defaultValue: value,
        name: fieldNames?.[key as keyof PortfolioReviewReport],
      };
    });
    return fieldData;
  }
  const fetchData = async (id: string, id2: string) => {
    console.log(id, id2);
    try {
      setFieldData({});
      const response = await axiosInstance.get(
        `/getPortfolioReviewByID/${id2}/${id}/${selectedFundID}`
      );
      if (response.data === "error") {
        setMessage("Error while fetching Data.");
        // Handle error
      } else {
        if (response.data.data.length === 0) {
          setMessage("There is no data with the given Date");
        } else {
          setIsSelected(true);
          const responseData = response.data.data[0];
          setFieldData(createFieldData(responseData));
        }
      }
    } catch (err) {
      setMessage("Error while fetching Data.");
      console.error(err);
    }
  };

  const getPortfolioNames = async (fund_ID: string = "") => {
    try {
      if (fund_ID === "") {
        fund_ID = fundID.current?.value ?? "";
      }
      const response = await axiosInstance.get("/getCompanies/" + fund_ID);
      const responseData = response.data.data;
      responseData.sort(
        (
          a: { portfolio_companyName: string },
          b: { portfolio_companyName: string }
        ) => {
          return a.portfolio_companyName
            .toLowerCase()
            .localeCompare(b.portfolio_companyName.toLowerCase());
        }
      );
      const portfolioNames = responseData.map((portfolio: IPortfolioQtr) => {
        return {
          [portfolio.portfolio_companyName as string]: portfolio.company_ID,
        };
      });

      if (portfolioID.current) {
        portfolioID.current.value = Object.values(
          portfolioNames[0]
        )[0] as string;
        portfolioID.current.selectedIndex = 0;
      }
      setPortfolioNames(portfolioNames);
      setSelectedPortfolioID(Object.values(portfolioNames[0])[0] as string);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getFundNames();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleBack = () => {
    navigate("/workflow"); // Navigate to the /workflow page
  };
  const handleChangeData = async (formData: {
    [fieldName: string]: string | undefined | Date | File | Boolean;
  }) => {
    try {
      const form = new FormData();
      Object.keys(formData).forEach((key) => {
        form.append(key, formData[key] as string);
      });
      const response = await axiosInstance.post("/addFormReview", form, {
        headers: {
          "Content-Type": "multipart/form-data", // or you can omit this line
        },
      });
      if (response.data === "error") {
        setMessage("Error while updating Data.");
      } else {
        setFieldData({});
        setMessage("Data updated successfully");
      }
      // Assuming you have the Base64 encoded ZIP string in a variable called `zipString`
    } catch (err) {
      setMessage("Error while updating Data.");
      console.error(err);
    } finally {
      setTimeout(() => {
        fetchData(selectedPorfolioID, formatDate(date));

        setMessage("");
      }, 3000);
    }
  };

  const getFundNames = async () => {
    try {
      const response = await axiosInstance.get("/fund-names");
      const responseData = response.data.data;
      responseData.sort(
        (a: { Fund_Name: string }, b: { Fund_Name: string }) => {
          return a.Fund_Name.toLowerCase().localeCompare(
            b.Fund_Name.toLowerCase()
          );
        }
      );
      // responseData.append({ Fund_Name: "Please select Fund", fund_ID: "" })
      const fundNames = responseData.map((fund: IFundStatic) => {
        return { [fund.Fund_Name]: fund.fund_ID };
      });
      // setSelectedFundID(Object.values(fundNames[0])[0] as string);
      setFundNames(fundNames);
      if (fundID.current) {
        fundID.current.value = Object.values(fundNames[0])[0] as string;
      }
      setSelectedFundID(Object.values(fundNames[0])[0] as string);
      getPortfolioNames(Object.values(fundNames[0])[0] as string);
    } catch (error) {
      console.error(error);
    }
  };
  function formatDate(dateString: Date) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // +1 because getMonth() returns 0-11
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  }

  return (
    <React.Fragment>
      <Helmet title="Add Reporting Data" />
      <Typography variant="h3" gutterBottom display="inline">
        Portdolio Review
      </Typography>
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
        }}
      >
        <Breadcrumbs aria-label="Breadcrumb" mt={2}>
          <Link component={NavLink} to="/">
            Dashboard
          </Link>
          <Link component={NavLink} to="/workFlow">
            WorkFlow
          </Link>
          <Typography>Portfolio Review Update</Typography>
        </Breadcrumbs>
      </div>

      <Divider my={6} />
      <Paper
        style={{
          padding: "10px",
        }}
      >
        <div>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            padding="10px"
          >
            <div>
              <label
                style={{
                  marginRight: "10px",
                  marginTop: "10px",
                  fontWeight: "bold",
                }}
              >
                Select a date :
              </label>
              <DatePicker
                format="dd-MM-yyyy"
                label="Reporting Date"
                value={date}
                onChange={(newValue) => {
                  if (newValue !== null) {
                    setDate(newValue);
                  }
                }}
              />
            </div>
            <Button
              startIcon={<ArrowBackIcon />}
              variant="contained"
              color="primary"
              onClick={handleBack}
            >
              Back
            </Button>
          </Box>
          <div>
            <div>Fund Name:</div>
            <select
              ref={fundID}
              onChange={() => {
                setSelectedFundID(fundID.current?.value ?? "");
                getPortfolioNames();
              }}
              style={{
                marginBottom: "20px",
                backgroundColor: "#f2f2f2", // Background color
                color: "#333", // Text color
                padding: "10px", // Padding
                border: "1px solid #ccc", // Border
                borderRadius: "5px", // Border radius
                outline: "none", // Remove outline when selected
                boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)", // Box shadow
                width: "200px", // Width
                cursor: "pointer", // Cursor style
              }}
            >
              {fundNames.map((fund, index) => {
                const fundName = Object.keys(fund)[0];
                const fundID = fund[fundName];
                return (
                  <option key={index} value={fundID}>
                    {fundName}
                  </option>
                );
              })}
            </select>
            <select
              ref={portfolioID}
              onChange={(e) => {
                setSelectedPortfolioID(e.target.value);
              }}
              style={{
                marginTop: "10px",
                backgroundColor: "#f2f2f2", // Background color
                color: "#333", // Text color
                padding: "10px", // Padding
                border: "1px solid #ccc", // Border
                borderRadius: "5px", // Border radius
                outline: "none", // Remove outline when selected
                boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)", // Box shadow
                width: "225px", // Width
                cursor: "pointer", // Cursor style
                marginLeft: "40px",
              }}
            >
              {portfolioNames.map((portfolio, index) => {
                const portfolioName = Object.keys(portfolio)[0];
                return (
                  <option key={index} value={portfolioName}>
                    {portfolioName}
                  </option>
                );
              })}
            </select>
            <button
              onClick={() => {
                fetchData(selectedPorfolioID, formatDate(date));
              }}
              type="submit"
              style={{
                marginLeft: "20px",
                padding: "12px 24px",
                backgroundColor: "#007bff",
                color: "#fff",
                border: "none",
                borderRadius: "4px",
                cursor: "pointer",
              }}
            >
              Get data
            </button>
          </div>
          {isSelected && (
            <DynamicForm3
              fieldData={fieldData}
              fieldData2={fieldData}
              changedata={handleChangeData}
              staticData={false}
              date={formatDate(date)}
              isUpdate={false}
            />
          )}
          {message !== "" && <>{message}</>}
        </div>
      </Paper>
    </React.Fragment>
  );
}
Portfolio_Review_Table.getLayout = function getLayout(page: ReactElement) {
  return <DashboardLayout>{page}</DashboardLayout>;
};
export default Portfolio_Review_Table;
