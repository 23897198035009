import React, { ReactElement, useRef } from "react";
import styled from "@emotion/styled";
import { useState } from "react";
import DashboardLayout from "../../../../layouts/Dashboard";
import { Helmet } from "react-helmet-async";
import { NavLink, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Box,
  Button,
  CardContent,
  Grid,
  Card as MuiCard,
  Divider as MuiDivider,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import axiosInstance from "../../../../utils/axios/axios.instance";
import {
  fieldNames,
  marketWatchUS,
  orderArray,
} from "../../../../interfaces/marketWatchUs";
import {
  fieldNamesCA,
  marketWatchCA,
  orderArrayCA,
} from "../../../../interfaces/marketWatchCa";
import { DatePicker } from "@mui/x-date-pickers";
import DynamicForm3 from "../../../../components/Workflow/workFlow_dataUpdateForm";
import { toast } from "react-toastify";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
function Market_watch() {
  const [isCAdata, setIsCAdata] = useState(false);
  const [isUSdata, setIsUSdata] = useState(false);
  const [isCAPreviousData, setIsCAPreviousData] = useState(false);
  const [isUSPreviousData, setIsUSPreviousData] = useState(false);
  const [fieldData, setFieldData] = useState({});
  const [fieldData2, setFieldData2] = useState({});
  const [date, setDate] = useState<Date>(new Date());
  const [usfieldData, setUSFieldData] = useState({});
  const [usfieldData2, setUSFieldData2] = useState({});
  const marketID = useRef<HTMLSelectElement>(null);
  const [SelcetedMarket, setSelcetedMarket] = useState("Canada");
  const [marketType] = useState<Array<{ [key: string]: string }>>([
    {
      Canada: "Canada",
    },
    {
      USA: "USA",
    },
  ]);
  const navigate = useNavigate();

  function createFieldData(responseData: marketWatchUS): {
    [key: string]: {
      type: string | Date;
      defaultValue:
        | string
        | number
        | undefined
        | Date
        | File
        | Boolean
        | string[];
      name: string | undefined;
    };
  } {
    const excludedFields = ["_id"];
    const fieldData: {
      [key: string]: {
        type: string | Date;
        defaultValue:
          | string
          | number
          | undefined
          | Date
          | File
          | Boolean
          | string[];
        name: string | undefined;
      };
    } = {};

    orderArray.forEach((key) => {
      if (excludedFields.includes(key) || !(key in responseData)) {
        return;
      }

      const value = responseData[key as keyof marketWatchUS];
      let type: string;

      if (typeof value === "string") {
        type = "text";
      } else if (typeof value === "boolean") {
        type = "checkbox";
      } else if (typeof value === "number") {
        type = "number";
      } else if (Array.isArray(value)) {
        type = "array";
      } else {
        type = typeof value;
      }

      fieldData[key] = {
        type,
        defaultValue: value,
        name: fieldNames?.[key as keyof marketWatchUS],
      };
    });
    return fieldData;
  }

  function createFieldDataCA(responseData: marketWatchCA): {
    [key: string]: {
      type: string | Date;
      defaultValue:
        | string
        | number
        | undefined
        | Date
        | File
        | Boolean
        | string[];
      name: string | undefined;
    };
  } {
    const excludedFields = ["_id"];
    const fieldData: {
      [key: string]: {
        type: string | Date;
        defaultValue:
          | string
          | number
          | undefined
          | Date
          | File
          | Boolean
          | string[];
        name: string | undefined;
      };
    } = {};

    orderArrayCA.forEach((key) => {
      if (excludedFields.includes(key) || !(key in responseData)) {
        return;
      }

      const value = responseData[key as keyof marketWatchCA];
      let type: string;

      if (typeof value === "string") {
        type = "text";
      } else if (typeof value === "boolean") {
        type = "checkbox";
      } else if (typeof value === "number") {
        type = "number";
      } else if (Array.isArray(value)) {
        type = "array";
      } else {
        type = typeof value;
      }

      fieldData[key] = {
        type,
        defaultValue: value,
        name: fieldNamesCA?.[key as keyof marketWatchCA],
      };
    });
    return fieldData;
  }
  const fetchCAData = async (id: string) => {
    try {
      setFieldData({});
      // Use params to send query parameters with a GET request
      const response = await axiosInstance.get(`/getCA_MarketWatchByID/${id}`);
      const response2 = await axiosInstance.get(
        `/getCA_MarketCurrentData/${id}`
      );
      if (response2.data.data.length === 0) {
      }
      if (response.data === "error") {
        toast.error("Error while fetching Data.");
      } else {
        if (response.data.data.length === 0) {
          toast.error("There is no data with the given Date");
        } else {
          response.data.data !== null ? setIsCAdata(true) : setIsCAdata(false);
          const responseData = response.data.data[0];
          const responseData2 = response2.data.data[0];
          if (responseData2 !== undefined) {
            setIsCAPreviousData(true);
            setFieldData2(createFieldDataCA(responseData2));
          } else {
            setIsCAPreviousData(false);
            setFieldData2(
              createFieldDataCA({
                _id: 0,
                Reporting_Year: id,
                canprov_Ontario_No_of_Deals: 0,
                canprov_Ontario_No_of_Companies: 0,
                canprov_Ontario_No_of_Firms: 0,
                canprov_Quebec_No_of_Deals: 0,
                canprov_Quebec_No_of_Companies: 0,
                canprov_Quebec_No_of_Firms: 0,
                canprov_British_Columbia_No_of_Deals: 0,
                canprov_British_Columbia_No_of_Companies: 0,
                canprov_British_Columbia_No_of_Firms: 0,
                canprov_Alberta_No_of_Deals: 0,
                canprov_Alberta_No_of_Companies: 0,
                canprov_Alberta_No_of_Firms: 0,
                canprov_Nova_Scotia_No_of_Deals: 0,
                canprov_Nova_Scotia_No_of_Companies: 0,
                canprov_Nova_Scotia_No_of_Firms: 0,
                canprov_Newfoundland_No_of_Deals: 0,
                canprov_Newfoundland_No_of_Companies: 0,
                canprov_Newfoundland_No_of_Firms: 0,
                canprov_Manitoba_No_of_Deals: 0,
                canprov_Manitoba_No_of_Companies: 0,
                canprov_Manitoba_No_of_Firms: 0,
                canprov_Saskatchewan_No_of_Deals: 0,
                canprov_Saskatchewan_No_of_Companies: 0,
                canprov_Saskatchewan_No_of_Firms: 0,
                canprov_New_Brunswick_No_of_Deals: 0,
                canprov_New_Brunswick_No_of_Companies: 0,
                canprov_New_Brunswick_No_of_Firms: 0,
                canprov_Prince_Edward_Island_No_of_Deals: 0,
                canprov_Prince_Edward_Island_No_of_Companies: 0,
                canprov_Prince_Edward_Island_No_of_Firms: 0,
                canprov_Total_No_of_Deals: 0,
                canprov_Total_No_of_Companies: 0,
                canprov_Total_No_of_Firms: 0,
                canind_Medical_Health_Life_Science_No_of_Deals: 0,
                canind_Medical_Health_Life_Science_No_of_Companies: 0,
                canind_Medical_Health_Life_Science_No_of_Firms: 0,
                canind_Information_Technology_No_of_Deals: 0,
                canind_Information_Technology_No_of_Companies: 0,
                canind_Information_Technology_No_of_Firms: 0,
                canind_Cleantech_No_of_Deals: 0,
                canind_Cleantech_No_of_Companies: 0,
                canind_Cleantech_No_of_Firms: 0,
                canind_Traditional_No_of_Deals: 0,
                canind_Traditional_No_of_Companies: 0,
                canind_Traditional_No_of_Firms: 0,
                canind_Total_No_of_Deals: 0,
                canind_Total_No_of_Companies: 0,
                canind_Total_No_of_Firms: 0,
                canfr_Amount_Raised_in_Range_CAD_Mil: 0,
                canfr_AB: 0,
                canfr_BC: 0,
                canfr_MB: 0,
                canfr_SK: 0,
                canfr_NF: 0,
                canfr_PEI: 0,
                canfr_NB: 0,
                canfr_NS: 0,
                canfr_ON: 0,
                canfr_QC: 0,
                canexits_IPO_Number_of_Deals: 0,
                canexits_IPO_No_Of_IPO_Exits: 0,
                canexits_IPO_No_of_Deals_w_MA_Deal_Value: 0,
                canexits_Trade_Sale_MA_Number_of_Deals: 0,
                canexits_Trade_Sale_MA_No_Of_IPO_Exits: 0,
                canexits_Trade_Sale_MA_No_of_Deals_w_MA_Deal_Value: 0,
                canexits_Secondary_Sale_Number_of_Deals: 0,
                canexits_Secondary_Sale_No_Of_IPO_Exits: 0,
                canexits_Secondary_Sale_No_of_Deals_w_MA_Deal_Value: 0,
                canexits_Total_Number_of_Deals: 0,
                canexits_Total_No_Of_IPO_Exits: 0,
                canexits_Total_No_of_Deals_w_MA_Deal_Value: 0,
                canque_Information_Technology_No_of_Deals: 0,
                canque_Information_Technology_No_of_Companies: 0,
                canque_Information_Technology_No_of_Firms: 0,
                canque_Medical_Health_Life_Science_No_of_Deals: 0,
                canque_Medical_Health_Life_Science_No_of_Companies: 0,
                canque_Medical_Health_Life_Science_No_of_Firms: 0,
                canque_Cleantech_No_of_Deals: 0,
                canque_Cleantech_No_of_Companies: 0,
                canque_Cleantech_No_of_Firms: 0,
                canque_Traditional_No_of_Deals: 0,
                canque_Traditional_No_of_Companies: 0,
                canque_Traditional_No_of_Firms: 0,
                canque_Total_No_of_Deals: 0,
                canque_Total_No_of_Companies: 0,
                canque_Total_No_of_Firms: 0,
              })
            );
          }
          setFieldData(createFieldDataCA(responseData));
        }
      }
    } catch (err) {
      toast.error("Error while fetching Data.");
      console.error(err);
    }
  };
  const fetchUSdData = async (id: string) => {
    try {
      setUSFieldData({});
      // Use params to send query parameters with a GET request
      const response = await axiosInstance.get(`/getUSMarketWatchByID/${id}`);
      const response2 = await axiosInstance.get(
        `/getUS_MarketCurrentData/${id}`
      );
      if (response.data === "error") {
        toast.error("Error while fetching Data.");
        // Handle error
      } else {
        if (response.data.data.length === 0) {
          toast.error("There is no data with the given Date");
        } else {
          response.data.data !== null ? setIsUSdata(true) : setIsUSdata(false);
          const responseData = response.data.data[0];
          const responseData2 = response2.data.data[0];
          if (responseData2 !== undefined) {
            setIsUSPreviousData(true);
            setUSFieldData2(createFieldData(responseData2));
          } else {
            setIsUSPreviousData(false);
            setUSFieldData2(
              createFieldData({
                _id: 0,
                Reporting_Year: id,
                dealvalueUS_Software: 0,
                dealvalueUS_Pharma_Biotech: 0,
                dealvalueUS_Other: 0,
                dealvalueUS_Media: 0,
                dealvalueUS_IT_Hardware: 0,
                dealvalueUS_HC_Services_Systems: 0,
                dealvalueUS_HC_Devices_Supplies: 0,
                dealvalueUS_Energy: 0,
                dealvalueUS_Consumer_Goods_Recreation: 0,
                dealvalueUS_Commercial_Services: 0,
                dealvalueUS_Transportation: 0,
                dealvalueUS_Total_US_M: 0,
                dealvalueUS_Life_Sciences_Deal_Value_B: 0,
                dealvalueUS_Life_Sciences_as_Percent_of_Total_US_VC: 0,
                dealvalueUS_IT_M: 0,
                dealvalueUS_LS_M: 0,
                dealvalueUS_CT_M: 0,
                dealvalueUS_TRAD_M: 0,
                dealcountUS_Software: 0,
                dealcountUS_Pharma_Biotech: 0,
                dealcountUS_Other: 0,
                dealcountUS_Media: 0,
                dealcountUS_IT_Hardware: 0,
                dealcountUS_HC_Services_Systems: 0,
                dealcountUS_HC_Devices_Supplies: 0,
                dealcountUS_Energy: 0,
                dealcountUS_Consumer_Goods_Recreation: 0,
                dealcountUS_Commercial_Services: 0,
                dealcountUS_Transportation: 0,
                dealcountUS_Total_US: 0,
                dealcountUS_Life_Sciences_Deal_Count: 0,
                dealcountUS_Life_Sciences_as_Percent_of_Total_US_VC: 0,
                dealcountUS_Number_IT: 0,
                dealcountUS_Number_LS: 0,
                dealcountUS_Number_CT: 0,
                dealcountUS_Number_TRAD: 0,
                frUS_RaisedbyFunds: 0,
                frUS_Funds: 0,
                exitcountUS_Acquisition: 0,
                exitcountUS_IPO: 0,
                exitcountUS_Buyout_Count: 0,
                exitvalueUS_Acquisition: 0,
                exitvalueUS_IPO: 0,
                exitvalueUS_Buyout_Count: 0,
                frsizeUS_Under_$50M: 0,
                frsizeUS_$50M_$100M: 0,
                frsizeUS_$100M_$250M: 0,
                frsizeUS_$250M_$500M: 0,
                frsizeUS_$500M_$1B: 0,
                frsizeUS_$1B: 0,
                frsizeUS_Undisclosed: 0,
              })
            );
          }
          setUSFieldData(createFieldData(responseData));
        }
      }
    } catch (err) {
      toast.error("Error while fetching Data.");
      console.error(err);
    }
  };

  const handleCAChangeData = async (formData: {
    [fieldName: string]: string | undefined | Date | File | Boolean;
  }) => {
    try {
      const form = new FormData();
      const Reporting_Year = date?.getFullYear().toString();
      const formDataObject = formData;
      form.append("Reporting_Year", Reporting_Year);
      form.append("formData", JSON.stringify(formDataObject));

      if (isCAPreviousData) {
        const response = await axiosInstance.post(
          "/updateCAMarketWatch",
          form,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (response.data === "error") {
          toast.error("Error while updating Data.");
        } else {
          setFieldData({});
          toast.success("Data updated successfully");
        }
      } else {
        const response = await axiosInstance.post("/addCAMarketWatch", form, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        if (response.data === "error") {
          toast.error("Error while updating Data.");
        } else {
          setFieldData({});
          toast.success("Data updated successfully");
        }
      }

      // Assuming you have the Base64 encoded ZIP string in a variable called `zipString`
    } catch (err) {
      toast.error("Error while updating Data.");
      console.error(err);
    } finally {
      setTimeout(() => {
        // setIsCAdata(false);
        fetchCAData(date?.getFullYear().toString());
      }, 3000);
    }
  };
  const handleUSChangeData = async (formData: {
    [fieldName: string]: string | undefined | Date | File | Boolean;
  }) => {
    setIsUSdata(false);
    try {
      const form = new FormData();
      const Reporting_Year = date?.getFullYear().toString();
      const formDataObject = formData;
      form.append("Reporting_Year", Reporting_Year);
      form.append("formData", JSON.stringify(formDataObject));
      if (isUSPreviousData) {
        const response = await axiosInstance.post(
          "/updateUSMarketWatchByID",
          form,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (response.data === "error") {
          toast.error("Error while updating Data.");
        } else {
          setFieldData({});
          toast.success("Data updated successfully");
        }
      } else {
        const response = await axiosInstance.post("/addUSMarketWatch", form, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        if (response.data === "error") {
          toast.error("Error while updating Data.");
        } else {
          setFieldData({});
          toast.success("Data updated successfully");
        }
      }
      // Assuming you have the Base64 encoded ZIP string in a variable called `zipString`
    } catch (err) {
      toast.error("Error while updating Data.");
      console.error(err);
    } finally {
      setTimeout(() => {
        fetchUSdData(date?.getFullYear().toString());
      }, 3000);
    }
  };
  const handleBack = () => {
    navigate("/workflow"); // Navigate to the /workflow page
  };

  return (
    <React.Fragment>
      <Helmet title="Blank" />
      <Typography variant="h3" gutterBottom display="inline">
        Market Watch
      </Typography>
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
        }}
      >
        <Breadcrumbs aria-label="Breadcrumb" mt={2}>
          <Link component={NavLink} to="/">
            Dashboard
          </Link>
          <Link component={NavLink} to="/workFlow">
            WorkFlow
          </Link>
          <Typography>Market watch</Typography>
        </Breadcrumbs>
      </div>
      <Divider my={6} />
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card mb={6}>
            <CardContent>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                padding="10px"
              >
                <div style={{ paddingTop: "20px" }}>
                  <div style={{ marginRight: "20px" }}>Select Market:</div>
                  <select
                    ref={marketID}
                    onChange={(e) => {
                      setSelcetedMarket(e.target.value);
                      setIsCAdata(false);
                      setIsUSdata(false);
                    }}
                    style={{
                      backgroundColor: "#f2f2f2", // Background color
                      color: "#333", // Text color
                      padding: "10px", // Padding
                      border: "1px solid #ccc", // Border
                      borderRadius: "5px", // Border radius
                      outline: "none", // Remove outline when selected
                      boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)", // Box shadow
                      width: "250px", // Width
                      cursor: "pointer", // Cursor style
                    }}
                  >
                    {marketType.map((market, index) => {
                      const ESGName = Object.keys(market)[0];
                      const ESGID = market[ESGName];
                      return (
                        <option key={index} value={ESGID}>
                          {ESGName}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <Button
                  startIcon={<ArrowBackIcon />}
                  variant="contained"
                  color="primary"
                  onClick={handleBack}
                  style={{ backgroundColor: "#007bff", borderRadius: "18px" }}
                >
                  Back
                </Button>
              </Box>
              <div
                style={{
                  marginTop: "20px",
                  marginLeft: "10px",
                }}
              >
                <DatePicker
                  format="dd/MM/yyyy"
                  label="Reporting Date"
                  value={date}
                  onChange={(newValue) => {
                    if (newValue !== null) {
                      setDate(newValue);
                    }
                  }}
                />
              </div>
              <button
                onClick={() => {
                  if (SelcetedMarket === "Canada") {
                    fetchCAData(date?.getFullYear().toString());
                  } else {
                    fetchUSdData(date?.getFullYear().toString());
                  }
                }}
                type="submit"
                style={{
                  marginTop: "20px",
                  marginLeft: "10px",
                  padding: "12px 24px",
                  backgroundColor: "#007bff",
                  color: "#fff",
                  border: "none",
                  borderRadius: "18px",
                  cursor: "pointer",
                }}
              >
                Get data
              </button>
              {isCAdata && SelcetedMarket === "Canada" ? (
                <DynamicForm3
                  fieldData={fieldData}
                  fieldData2={fieldData2}
                  changedata={handleCAChangeData}
                  staticData={false}
                  date={date.getFullYear().toString()}
                  isUpdate={isCAPreviousData}
                />
              ) : (
                <></>
              )}
              {isUSdata && SelcetedMarket === "USA" ? (
                <DynamicForm3
                  fieldData={usfieldData}
                  fieldData2={usfieldData2}
                  changedata={handleUSChangeData}
                  staticData={false}
                  date={date.getFullYear().toString()}
                  isUpdate={isUSPreviousData}
                />
              ) : (
                <></>
              )}
              {/* {message !== "" && <>{message}</>} */}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
Market_watch.getLayout = function getLayout(page: ReactElement) {
  return <DashboardLayout>{page}</DashboardLayout>;
};
export default Market_watch;
