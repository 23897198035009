export const keyOrder = [
    "Reporting_Date",
    "portfolio_companyName",
    "PublicMkt_Status",
    "portfolio_firstInvestmentDate",
    "portfolio_exitDate",
    "portfolio_realizedCost",
    "portfolio_realizedGainLoss",
    "portfolio_unrealizedCost",
    "portfolio_unrealizedNAV",
    "FDO",
    "portfolio_GP_Lead",
    "Textboxes_QtrUpdate",
    "portfolio_realizedValue",
    "LastValChange_QQ",
    "LastValChange_YYYY",
    "LastValChange_Nature",
    "Textboxes_Valuation",
    "Textboxes_Milestones-ST",
    "Textboxes_Outlook-LT",
    "portfolio_series",
    "portfolio_investedbyGP",
    "portfolio_finalsizeRound",
    "portfolio_noOfEmployee",
    "portfolio_eqval",
    "portfolio_entval",
    "portfolio_Net_Debt_Cash",
    "Currency",
    "portfolio_FiscalYearEnd",
    "created_At",
    "run_id",
    "record_type",
    "Textboxes_Overview",
    "portfolio_revenue",
    "portfolio_ebitda",
    "portfolio_CapitalInvested_NonDil",
    "portfolio_reservedCapital",
    "portfolio_moicFinal",
    "portfolio_unrealizedNAV_NonDil",
    "portfolio_initialInvt_NonDil",
    "portfolio_initialInvt_Dilutive",
    "portfolio_capitalInvested",
    "portfolio_capitalReserved",
    "portfolio_ROI",
    "portfolio_unrealizedGainLoss",
    "portfolio_totalGainLoss",
    "portfolio_moc",
    "portfolio_initialInvt_FDO",
    "Fund_Name",
    "fund_ID",
    "company_ID",
    "BREAK",
    "portfolio_companyOverview",
    "portfolio_currency",
    "portfolio_companyState",
    "portfolio_sector",
    "fund_commitmentPeriodEndDate",
    "positive_impact",
    "portfolio_companyCountry",
    "portfolio_geoLocation",
    "LP_name",
];
export const FundkeyOrder = [
    "Reporting_Date",
    "Fund_Name",
    "fund_nav",
    "fund_called",
    "fund_dpi",
    "fund_tvpi",
    "fund_numberOfInvestmentProjected",
    "fund_netAssetValueGPCarried",
    "fund_recallableDistributions",
    "fund_recalledAmount",
    "fund_netIRR",
    "fund_gpCarriedDistribution",
    "fund_PartnerExp_toDate",
    "fund_MgmtFees_toDate",
    "fund_OrgFees_toDate",
    "fund_distributed",
    "fund_numberOfInvestmentToDate",
    "fund_IRR",
    "record_type",
    "run_id",
    "fund_Textboxes_QtrlyreportUpdate",
    "Textboxes_Strategy",
    "Textboxes_Outlook",
    "Textboxes_QtrlyUpdate",
    "Textboxes_FundPipe",
    "Textboxes_Co-InvestPipe",
    "Textboxes_Exits",
    "fund_capitalReserved",
    "fund_capitalAvailable",

    "BREAK",
    "fund_ID",
    "LP_name",
    "fund_capitalInvested",
    "fund_sectorFocus",
    "fund_strategy",
    "fund_stage",
    "fund_gpCommitment",
    "fund_region",
    "lp_investmentStake",
    "fund_fundSizeFinalClose",
    "fund_Life_LPA",
    "fund_teamBackground",
    "fund_investmentManager",
    "fund_geoLocation",
    "fund_hurdleRate",
    "fund_removalWithCause",
    "fund_removalWithoutCause",
    "fund_carriedInterest",
    "fund_treatmentOfCarriedInterestOnRemoval",
    "fund_keyPersonProvision",
    "fund_governingLawAndJurisdiction",
    "fund_vintage",
    "fund_endDate",
    "fund_currency",
    "fund_commitment",
    "fund_Carry",
    "fund_FirstClosing",
    "fund_percentageFundSizeLPA",
    "fund_InvPer",
    "fund_percentageNICLPA",
    "fund_investmentAdvisor",
    "fund_Fees",
    "fund_Pref",
];



















export const constKey = [
    "Reporting_Date",
    "portfolio_companyName",
    "Fund_Name",
    "fund_ID",
    "company_ID",
    "created_At",
];


export const keyDisplayNames: { [key: string]: string } = {
    fund_strategy: "Fund description",
    fund_nav: "Net Asset Value",
    fund_dpi: "DPI",
    fund_tvpi: "TVPI",
    fund_capitalCalled: "Capital Called",
    fund_numberOfInvestmentProjected: "Investments Projected",
    fund_recallableDistributions: "Recallable Distributions",
    fund_netIRR: "Net IRR",
    fund_gpCarriedDistribution: "GP Carried Distribution",
    fund_distributed: "Fund Distributed",
    fund_IRR: "IRR",
    fund_currency: "Currency",
    fund_Fees: "Fund Fees",
    fund_Life_LPA: "Fund Life",
    Fund_Name: "Fund Name",
    fund_generalPartner: "General Partner",
    fund_investmentAdvisor: "Investment Advisor",
    fund_investmentManager: "Investment Manager",
    fund_fundSizeFinalClose: "Fund Size (Final Close)",
    fund_FirstClosing: "First Closing Date",
    fund_Life_Exp: "Fund Life",
    fund_commitment: "FoF Commitment",
    fund_gpCommitment: "GP Commitment",
    fund_legalStructure: "Legal Structure",
    fund_carriedInterest: "fund carried interest",
    fund_hurdleRate: "Hurdle rate",
    fund_clawBack: "GP Clawback",
    fund_removalWithCause: "Removal with Cause",
    fund_removalWithoutCause: "Removal without Cause",
    fund_treatmentOfCarriedInterestOnRemoval:
        "Treatment of Carried Interest on Removal",
    fund_keyPersonProvision: "Key Person Provision",
    fund_governingLawAndJurisdiction: "Governing Law and Jurisdiction",
    fund_stage: "Stage",
    fund_region: "Fund Region",
    fund_sectorFocus: "Sector Focus",
    fund_vintage: "Vintage",
    FTLA_InvPer: "Investment Period",
    fund_valueNetAsset: "Fund Net Asset Value",
    fund_expCalledRecalled: "Capital Called/Recalled",
    fund_percentageFundSizeLPA: "Percentage of Fund Size to LPA",
    fund_percentageNICLPA: "Percentage of Net Invested Capital to LPA",
    fund_InvPer_ExpAct: "Investment Period vs Expected Actual",
    fund_endDate: "End Of Life",
    Textboxes_Outlook: "Outlook",
    Textboxes_QtrlyUpdate: "Qtrly Update",
    Textboxes_FundPipe: "Fund Pipeline",
    "Textboxes_Co-InvestPipe": "Co-Investment Pipeline",
    Textboxes_Exits: "Exits",
    fund_capitalReserved: "Capital Reserved",
    fund_capitalAvailable: "Capital Available",
    fund_capitalInvested
        : "Capital Invested",
    fund_numberOfInvestmentToDate
        : "Number of Investments to Date",
    fund_called: "Fund Called",
    fund_InvPer: "Investment Period",
    fund_PartnerExp_toDate: "Partner Expenses to Date",
    fund_MgmtFees_toDate: "Management Fees to Date",
    fund_OrgFees_toDate: "Organization Fees to Date",
    fund_recalledAmount: "Recalled Amount",
    fund_highlights: "Highlights",
    fund_historicalTer: "historical terms",
    fund_websiteURL: "website URL",
    fund_ExpCarry: "expected carry",
    fund_overview: "overview",
    LP_name: "LP Name",
    fund_partners: "Partners",
    fund_teamSize: "Team size",
    lp_investmentStake: "Investment Stake",


};
export const PortfolioKeyNames: { [key: string]: string } = {
    Textboxes_Overview: "Portfolio Qtrly Update",
    PublicMkt_Status: "Public Market Status",
    portfolio_companyCountry: "Company Country",
    portfolio_firstInvestmentDate: "First Investment Date",
    portfolio_exitDate: "Exit Date",
    portfolio_realizedGainLoss: "Realized Investment Gain/Loss",
    portfolio_unrealizedCost: "Unrealized Investment Cost",
    portfolio_unrealizedNAV: "Unrealized Investment Net Asset Value",
    portfolio_currency: "Currency",
    portfolio_FiscalYearEnd: "Fiscal Year End",
    positive_impact: "Positive Impact",
    portfolio_realizedValue: "Realized Fund",
    portfolio_geoLocation: "Location coordinates",
    portfolio_companyOverview: "Company Description",
    portfolio_companyState: "Company States",
    portfolio_sector: "Sector",
    Textboxes_QtrUpdate: "Portfolio Qtrly Update",
    portfolio_revenue: "Revenue",
    portfolio_CapitalInvested_NonDil: "Capital Invested Non Dilutive",
    portfolio_reservedCapital: "Reserved Capital",
    portfolio_moicFinal: "MOIC Final",
    portfolio_unrealizedNAV_NonDil: "Unrealized NAV Non Dilutive",
    portfolio_initialInvt_NonDil: "Initial Investment Non Dilutive",
    portfolio_initialInvt_Dilutive: "Initial Investment Dilutive",
    portfolio_capitalInvested: "Capital Invested",
    portfolio_capitalReserved: "Capital Reserved",
    portfolio_ROI: "ROI",
    portfolio_unrealizedGainLoss: "Unrealized Gain/Loss",
    portfolio_totalGainLoss: "Total Gain/Loss",
    portfolio_moc: "MOC",
    portfolio_ebitda: "EBITDA",
    portfolio_initialInvt_FDO: "Intial Inveted Fully Diluted Ownership",
    portfolio_realizedCost: "Realized Cost",
    portfolio_GP_Lead: "GP Lead",
    FDO: "Fully Diluted Ownership",
    LastValChange_QQ: "Last Value Change quarter",
    LP_name: "LP Name",
    LastValChange_YYYY: "Last Value Change year",
    LastValChange_Nature: "Last Value Change Nature",
    Textboxes_Valuation: "Textboxes Valuation",
    "Textboxes_Milestones-ST": "Textboxes Milestones-ST",
    "Textboxes_Outlook-LT": "Textboxes Outlook-LT",
    portfolio_series: "Portfolio Series",
    portfolio_investedbyGP: "Invested by GP",
    portfolio_finalsizeRound: "Final Size Round",
    portfolio_noOfEmployee: "Number of Employees",
    portfolio_eqval: "Portfolio Equity Value",
    portfolio_entval: "Portfolio Enterprise Value",
    portfolio_Net_Debt_Cash: "Net Debt Cash",
};
