import { useEffect, useState } from "react";
import React from "react";
import { Helmet } from "react-helmet-async";
import {
  IFundRaising,
  IFundTrackRecord,
  ITeam,
  ITrackRecord,
  fieldNames,
  orderArray,
} from "../../../../interfaces/fundRaising.interface";
import axiosInstance from "../../../../utils/axios/axios.instance";
import DynamicForm2 from "../../../../components/fundRaising/FundRaising_UpdateForm";
function Update_Funds() {
  const [fieldData, setFieldData] = useState({});
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  function createFieldData(responseData: IFundRaising): {
    [key: string]: {
      type: string | Date;
      defaultValue:
        | string
        | undefined
        | Date
        | File
        | Boolean
        | ITeam[]
        | IFundTrackRecord[]
        | string[]
        | ITrackRecord[];
      name: string | undefined;
    };
  } {
    const excludedFields = [
      "_id",
      "ReportingDateKey",
      "ReportingDateString",
      "fund_nav",
      "fund_dpi",
      "fund_tvpi",
    ];

    const fieldData: {
      [key: string]: {
        type: string | Date;
        defaultValue:
          | string
          | undefined
          | Date
          | File
          | Boolean
          | ITeam[]
          | IFundTrackRecord[]
          | string[]
          | ITrackRecord[];
        name: string | undefined;
      };
    } = {};

    orderArray.forEach((key) => {
      if (excludedFields.includes(key) || !(key in responseData)) {
        return;
      }

      const value = responseData[key as keyof IFundRaising];
      let type: string;

      if (key === "fr_logo") {
        type = "file";
      } else if (typeof value === "string") {
        type = "text";
      } else if (typeof value === "boolean") {
        type = "checkbox";
      } else if (typeof value === "number") {
        type = "number";
      } else if (value instanceof Date) {
        type = "date";
      } else if (Array.isArray(value)) {
        type = "array";
      } else {
        type = typeof value;
      }

      fieldData[key] = {
        type,
        defaultValue: value,
        name: fieldNames?.[key as keyof IFundRaising],
      };
    });
    return fieldData;
  }
  // Define an async function inside useEffect
  const fetchData = async () => {
    try {
      // Use params to send query parameters with a GET request
      const response = await axiosInstance.get("/getFundRaising/");
      if (response.data === "error") {
        setMessage("Error while fetching Data.");
        // Handle error
      } else {
        if (response.data.data.length === 0) {
          setFieldData({});
          setMessage("There is no data with the given Date");
        } else {
          const responseData = response.data.data[0];
          setFieldData(createFieldData(responseData));
          setName(responseData.fr_managerName);
          setMessage("");
        }
      }
    } catch (err) {
      setMessage("Error while fetching Data.");
      console.error(err);
    }
  };
  const handleChangeData = async (formData: {
    [fieldName: string]:
      | string
      | Boolean
      | Date
      | File
      | number
      | ITeam[]
      | string[]
      | ITrackRecord[]
      | IFundTrackRecord[];
  }) => {
    try {
      const form = new FormData();
      form.append("fund_ID", name);
      const fileKeys = [
        "fr_logo",
        "fr_deck_url",
        "fr_prospectus_url",
        "fr_esg_url",
        "fr_impactthesis_curl",
      ];
      // Iterate over each key to check for the file and append it if exists
      fileKeys.forEach((key) => {
        if (formData[key] && formData[key] instanceof File) {
          form.append(key, formData[key] as File); // Append the file to FormData with its key
          delete formData[key]; // Remove the file from formData after appending to avoid duplication
        }
      });
      if (Array.isArray(formData.Team)) {
        (formData.Team as ITeam[]).forEach((member: ITeam, index) => {
          // Append the file if 'profile_image' is an instance of File
          if (member.profile_image && member.profile_image instanceof File) {
            form.append(`team[${index}][profile_image]`, member.profile_image);
          }

          // Append the rest of the team member's data as a JSON string
        });
      }
      if (Array.isArray(formData.previous_investments)) {
        formData.previous_investments.forEach((member, index) => {
          // Append the file if 'profile_image' is an instance of File
          if (member && member instanceof File) {
            form.append(`previous_investments[${index}]`, member);
          }

          // Append the rest of the team member's data as a JSON string
        });
      }
      form.append("formData", JSON.stringify(formData));
      const response = await axiosInstance.post("/update_fundRaising", form, {
        headers: {
          "Content-Type": "multipart/form-data", // or you can omit this line
        },
      });
      if (response.data === "error") {
        setMessage("Error while updating Data.");
      } else {
        setFieldData({});
        setMessage("Data updated successfully");
        setTimeout(() => {
          setMessage("");
          fetchData();
        }, 4000);
      }
      // Assuming you have the Base64 encoded ZIP string in a variable called `zipString`
    } catch (err) {
      setMessage("Error while updating Data.");
      console.error(err);
    }
  };
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <React.Fragment>
      <Helmet title="Add Reporting Data" />
      <div>
        <h1>Fund Raising</h1>
        {Object.keys(fieldData).length !== 0 && (
          <>
            <DynamicForm2 fieldData={fieldData} changedata={handleChangeData} />
          </>
        )}
        {message !== "" && <>{message}</>}
      </div>
    </React.Fragment>
  );
}
export default Update_Funds;
