import {CSSProperties} from "react";
import axiosInstance from "../utils/axios/axios.instance";


const ExportCSVButton = ({collection}: {
    collection: string
}) => {
    const styles: { [key: string]: CSSProperties } = {
        button: {
            padding: "12px 24px",
            backgroundColor: "#007bff",
            color: "#fff",
            fontWeight: "bold",
            border: "none",
            borderRadius: "20px",
            cursor: "pointer",
            height: "42px",
        },
    };

    const handleExport = () => {
        axiosInstance.get("/exportData/" + collection, {
            responseType: 'blob'
        })
            .then(blob => {
                const url = window.URL.createObjectURL(new Blob([blob.data as any]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${collection}-data.csv`);
                document.body.appendChild(link);
                link.click();
                link.parentNode!.removeChild(link);
            })
            .catch(error => console.error('Error downloading the file', error));
    }


    return (
        <div>
            <button style={{...styles.button, marginLeft: "20px"}} onClick={handleExport}>
                Export as CSV
            </button>
        </div>
    );
}

export default ExportCSVButton;
