export interface ITeam {
  partner_name: string;
  partner_background: string;
  partner_url: string;
  profile_image: File | null;
}
export interface ITrackRecord {
  tr_date: string;
  tr_fund: string;
  tr_vintage: string;
  tr_size: number;
  tr_invested: number;
  tr_realized: number;
  tr_unrealized: number;
  tr_totalValue: string; // Assuming this can be an empty string or a number as string
  tr_grossIRR: string; // Assuming this can be an empty string or a number as string
  tr_netIRR: string; // Assuming this can be an empty string or a number as string
  tr_netTVPI: string; // Assuming this is always a string that can parse to number
  tr_lossRatio: string; // Assuming this can be an empty string or a number as string
}
export interface IFundTrackRecord {
  ftr_company: string;
  ftr_currency: string;
  ftr_region: string;
  ftr_sector: string;
  ftr_stage: string;
  ftr_investmentDate: string; // Assuming date format is "DD-MM-YYYY"
  ftr_exitDate: string; // Assuming date format is "DD-MM-YYYY" or empty string for no exit date
  ftr_invested: number;
  ftr_realized: number;
  ftr_unRealized: number;
  ftr_grossIRR: string; // Assuming this can be an empty string or a number as string
  ftr_netIRR: string; // Assuming this can be an empty string or a number as string
}
export interface IFundRaising {
  fr_logo: File;
  fr_managerName: string;
  fr_companyHQ: string;
  fr_presentationvideo: string;
  fr_executivesummary: string;
  fr_sector: string;
  fr_region: string;
  fr_geographicalFocus: string;
  fr_ticketrange: string;
  fr_ESGDI: string;
  fr_targetFundSize: string;
  fr_impactMetrics: string;
  fr_deck_checkbox: Boolean;
  fr_deck_url: File;
  fr_prospectus_checkbox: Boolean;
  fr_prospectus_url: File;
  fr_interview_checkbox: Boolean;
  fr_interview_url: string;
  fr_interview_description: string;
  fr_company_showreel_checkbox: Boolean;
  fr_company_showreel_url: string;
  fr_company_showreel_description: string;
  fr_investmentphilosophy: string;
  fr_investors: string;
  fr_activelyRaisingFunds: string;
  fr_esg_checkbox: Boolean;
  fr_esg_url: File;
  fr_impactthesis_checkbox: Boolean;
  fr_impactthesis_curl: File;
  fr_esg_policy: string;
  fr_goal1_checkbox: Boolean;
  fr_goal1_description: string;
  fr_goal2_checkbox: Boolean;
  fr_goal3_checkbox: Boolean;
  fr_goal4_checkbox: Boolean;
  fr_goal5_checkbox: Boolean;
  fr_goal6_checkbox: Boolean;
  fr_goal7_checkbox: Boolean;
  fr_goal8_checkbox: Boolean;
  fr_goal9_checkbox: Boolean;
  fr_goal10_checkbox: Boolean;
  fr_goal11_checkbox: Boolean;
  fr_goal12_checkbox: Boolean;
  fr_goal13_checkbox: Boolean;
  fr_goal14_checkbox: Boolean;
  fr_goal15_checkbox: Boolean;
  fr_goal16_checkbox: Boolean;
  fr_goal17_checkbox: Boolean;
  fr_goal2_description: string;
  fr_goal3_description: string;
  fr_goal4_description: string;
  fr_goal5_description: string;
  fr_goal6_description: string;
  fr_goal7_description: string;
  fr_goal8_description: string;
  fr_goal9_description: string;
  fr_goal10_description: string;
  fr_goal11_description: string;
  fr_goal12_description: string;
  fr_goal13_description: string;
  fr_goal14_description: string;
  fr_goal15_description: string;
  fr_goal16_description: string;
  fr_goal17_description: string;
  fr_investee_checkbox: Boolean;
  fr_investee_videoUrl: string;
  fr_investee_description: string;
  Team: Array<ITeam>;
  Track_Record: Array<ITrackRecord>;
  Fund_Track_Record: Array<IFundTrackRecord>;
  fr_alpha_company_checkbox: Boolean;
  fr_alpha_companyUrl: string;
  fr_alpha_company_description: string;
  fr_beta_company_checkbox: Boolean;
  fr_beta_companyUrl: string;
  fr_beta_company_description: string;
  fr_Fund_Name: string;
  fr_vintage: string;
  fr_trackRecord: string;
  fr_jurisdiction: string;
  fr_expectedIRR: string;
  fr_fundEconomics: string;
  fr_managerBackground: string;
  fr_subsector: string;
  fr_currency: string;
  fr_fund_TVPI: string;
  fr_fund_DPI: string;
  fr_crossoverNumber: string;
  fr_crossoverFundName: string;
  fr_crossOverCompanies: string;
  fr_geoLocation: string;
  fr_manageremail: string;
  previous_investments: Array<string>;
}

export const fieldNames: { [key in keyof IFundRaising]: string } = {
  fr_logo: "Fund Logo",
  fr_managerName: "Manager Name",
  fr_companyHQ: "Company Headquarters",
  fr_presentationvideo: "Presentation Video",
  fr_executivesummary: "Executive Summary",
  fr_sector: "Sector",
  fr_region: "Region",
  fr_geographicalFocus: "Geographical Focus",
  fr_ticketrange: "Ticket Range",
  fr_ESGDI: "ESGDI",
  fr_targetFundSize: "Target Fund Size",
  fr_impactMetrics: "Impact Metrics",
  fr_deck_checkbox: "Deck",
  fr_deck_url: "Deck URL",
  fr_prospectus_checkbox: "Prospectus",
  fr_prospectus_url: "Prospectus URL",
  fr_interview_checkbox: "Interview",
  fr_interview_url: "Interview URL",
  fr_interview_description: "Interview Description",
  fr_company_showreel_checkbox: "Company Showreel",
  fr_company_showreel_description: "Company Showreel Description",
  fr_company_showreel_url: "Company Showreel URL",
  fr_investmentphilosophy: "Investment Philosophy",
  fr_investors: "Investors",
  fr_activelyRaisingFunds: "Actively Raising Funds",
  fr_esg_checkbox: "ESG",
  fr_esg_url: "ESG URL",
  fr_impactthesis_checkbox: "Example Report",
  fr_impactthesis_curl: "Example Report URL",
  fr_esg_policy: "ESG Policy",
  fr_goal1_checkbox: "Goal 1",
  fr_goal2_checkbox: "Goal 2",
  fr_goal3_checkbox: "Goal 3",
  fr_goal4_checkbox: "Goal 4",
  fr_goal5_checkbox: "Goal 5",
  fr_goal6_checkbox: "Goal 6",
  fr_goal7_checkbox: "Goal 7",
  fr_goal8_checkbox: "Goal 8",
  fr_goal9_checkbox: "Goal 9",
  fr_goal10_checkbox: "Goal 10",
  fr_goal11_checkbox: "Goal 11",
  fr_goal12_checkbox: "Goal 12",
  fr_goal13_checkbox: "Goal 13",
  fr_goal14_checkbox: "Goal 14",
  fr_goal15_checkbox: "Goal 15",
  fr_goal16_checkbox: "Goal 16",
  fr_goal17_checkbox: "Goal 17",
  fr_goal1_description: "Goal 1 Description",
  fr_goal2_description: "Goal 2 Description",
  fr_goal3_description: "Goal 3 Description",
  fr_goal4_description: "Goal 4 Description",
  fr_goal5_description: "Goal 5 Description",
  fr_goal6_description: "Goal 6 Description",
  fr_goal7_description: "Goal 7 Description",
  fr_goal8_description: "Goal 8 Description",
  fr_goal9_description: "Goal 9 Description",
  fr_goal10_description: "Goal 10 Description",
  fr_goal11_description: "Goal 11 Description",
  fr_goal12_description: "Goal 12 Description",
  fr_goal13_description: "Goal 13 Description",
  fr_goal14_description: "Goal 14 Description",
  fr_goal15_description: "Goal 15 Description",
  fr_goal16_description: "Goal 16 Description",
  fr_goal17_description: "Goal 17 Description",
  fr_investee_checkbox: "Investee",
  fr_investee_videoUrl: "Investee Video URL",
  fr_investee_description: "Investee Description",
  Team: "Team",
  Track_Record: "Track Record",
  Fund_Track_Record: "Fund Track Record",
  fr_alpha_company_checkbox: "Alpha Company",
  fr_alpha_companyUrl: "Alpha Company URL",
  fr_alpha_company_description: "Alpha Company Description",
  fr_beta_company_checkbox: "Beta Company",
  fr_beta_companyUrl: "Beta Company URL",
  fr_beta_company_description: "Beta Company Description",
  fr_Fund_Name: "Fund Name",
  fr_vintage: "Vintange",
  fr_trackRecord: "Track Record",
  fr_jurisdiction: "Jurisdiction",
  fr_expectedIRR: "Expected IRR",
  fr_fundEconomics: "Fund Economics",
  fr_managerBackground: "Manager Background",
  fr_subsector: "Subsector",
  fr_currency: "Currency",
  fr_fund_TVPI: "Fund TVPI",
  fr_fund_DPI: "Fund DPI",
  fr_crossoverNumber: "Crossover Number",
  fr_crossoverFundName: "Crossover Fund Name",
  fr_crossOverCompanies: "Crossover Companies",
  fr_geoLocation: "Geographical Location",
  previous_investments: "Previous Investments",
  fr_manageremail: "Manager Email",
};

export const orderArray = [
  "fr_logo",
  "fr_managerName",
  "fr_manageremail",
  "fr_companyHQ",
  "fr_presentationvideo",
  "fr_executivesummary",
  "fr_sector",
  "fr_region",
  "fr_geoLocation",
  "fr_geographicalFocus",
  "fr_ticketrange",
  "fr_ESGDI",
  "fr_targetFundSize",
  "fr_impactMetrics",
  "fr_deck_checkbox",
  "fr_deck_url",
  "fr_prospectus_checkbox",
  "fr_prospectus_url",
  "fr_esg_checkbox",
  "fr_esg_url",
  "fr_impactthesis_checkbox",
  "fr_impactthesis_curl",
  "fr_interview_checkbox",
  "fr_interview_url",
  "fr_interview_description",
  "fr_company_showreel_checkbox",
  "fr_company_showreel_description",
  "fr_company_showreel_url",
  "fr_investmentphilosophy",
  "fr_investors",
  "fr_activelyRaisingFunds",
  "fr_esg_policy",
  "fr_goal1_checkbox",
  "fr_goal1_description",
  "fr_goal2_checkbox",
  "fr_goal2_description",
  "fr_goal3_checkbox",
  "fr_goal3_description",
  "fr_goal4_checkbox",
  "fr_goal4_description",
  "fr_goal5_checkbox",
  "fr_goal5_description",
  "fr_goal6_checkbox",
  "fr_goal6_description",
  "fr_goal7_checkbox",
  "fr_goal7_description",
  "fr_goal8_checkbox",
  "fr_goal8_description",
  "fr_goal9_checkbox",
  "fr_goal9_description",
  "fr_goal10_checkbox",
  "fr_goal10_description",
  "fr_goal11_checkbox",
  "fr_goal11_description",
  "fr_goal12_checkbox",
  "fr_goal12_description",
  "fr_goal13_checkbox",
  "fr_goal13_description",
  "fr_goal14_checkbox",
  "fr_goal14_description",
  "fr_goal15_checkbox",
  "fr_goal15_description",
  "fr_goal16_checkbox",
  "fr_goal16_description",
  "fr_goal17_checkbox",
  "fr_goal17_description",
  "fr_investee_checkbox",
  "fr_investee_description",
  "fr_investee_videoUrl",
  "Team",
  "Track_Record",
  "Fund_Track_Record",
  "previous_investments",
  "fr_alpha_company_checkbox",
  "fr_alpha_company_description",
  "fr_alpha_companyUrl",
  "fr_beta_company_checkbox",
  "fr_beta_company_description",
  "fr_beta_companyUrl",
  "fr_Fund_Name",
  "fr_vintage",
  "fr_trackRecord",
  "fr_jurisdiction",
  "fr_expectedIRR",
  "fr_fundEconomics",
  "fr_managerBackground",
  "fr_subsector",
  "fr_currency",
  "fr_fund_TVPI",
  "fr_fund_DPI",
  "fr_crossoverNumber",
  "fr_crossoverFundName",
  "fr_crossOverCompanies",
];
