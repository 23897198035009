import React, { CSSProperties, ChangeEvent, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { DatePicker } from "@mui/x-date-pickers";
import {
  Button,
  CardContent,
  Grid,
  Card as MuiCard,
  Divider as MuiDivider,
} from "@mui/material";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { toast } from "react-toastify";
import axiosInstance from "../../../utils/axios/axios.instance";
import {
  FormDataObject,
  FundName,
} from "../../../interfaces/updateScript.interface";
import { updateFundQurterlyData } from "../../HelperFunctions/updateScript";
import {
  portfolioReviewconstKey,
  portfolioReviewDataFields,
  PortfolioReviewKeyNames,
  portfolioReviewKeyOrder,
} from "./reviewHelper";
import PortfolioReviewExtractedForm from "./portfolioreviewextractionform";

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const form = React.createRef<HTMLFormElement>();
const PortfolioDataUploads = () => {
  const [isCompanyUpdate, setIsCompanyUpdate] = useState(false);
  const [date, setDate] = useState<Date | null>(new Date());
  const [isSelected, setIsSelected] = useState<boolean>(false);
  const [selectedFundId, setSelectedFundId] = useState<string>("");
  const [inputvalue, setInputValue] = useState<any>({});
  const [fundName, setFundName] = useState<string>("");
  const [staticValue, setStaticValue] = useState(portfolioReviewDataFields);
  const [currentRunID, setCurrentRunID] = useState("");
  const [extractedData, setExtractedData] = useState<any[]>([]);
  const [filename, setFileName] = useState<FundName[]>([]);
  const [fundPDF, setFundPDF] = useState<string>("");

  const styles: { [key: string]: CSSProperties } = {
    tableLabel: {
      fontWeight: "bold",
      padding: "10px",
      textAlign: "left",
      fontSize: "15px",
    },
    label: {
      fontWeight: "bold",
      padding: "10px",
      textAlign: "left", // Align the text to the right within the label cell
    },
    input: {
      padding: "10px",
      border: "1px solid #ccc",
      borderRadius: "4px",
      width: "70%", // Make input take full width of its cell
    },
    select: {
      display: "flex", // Make select fill the cell
      backgroundColor: "#f2f2f2", // Background color
      color: "#333", // Text color
      padding: "10px", // Padding
      border: "1px solid #ccc", // Border
      borderRadius: "5px", // Border radius
      outline: "none", // Remove outline when selected
      boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)", // Box shadow
      width: "200px", // Width
      cursor: "pointer", // Cursor style
    },
    defaultValue: {
      padding: "10px", // Add padding for aesthetic spacing
      marginLeft: "20px", // Space from the input cell
    },
    buttonContainer: {
      textAlign: "center",
      marginTop: "20px", // Space above the button
    },
    button: {
      padding: "12px 24px",
      backgroundColor: "#007bff",
      color: "#fff",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
    },
  };
  const theme = createTheme({
    components: {
      MuiToggleButton: {
        styleOverrides: {
          root: {
            "&.Mui-selected, &.Mui-selected:hover": {
              // Styles applied to the button when it is in the selected state
              color: "white",
              backgroundColor: "#1976d2", // A shade of blue
            },
          },
        },
      },
    },
  });

  useEffect(() => {
    document.title = "AI Extracted Data";
    // setDate(reportingDate ? convertStringToDate(reportingDate) : new Date());
    getFileNames();
  }, []);
  const UpdateCompanyData = async () => {
    try {
      let formData2: FormDataObject = {};
      delete inputvalue.BREAK;
      formData2 = inputvalue;
      const form = new FormData();
      delete formData2._id;
      formData2.fund_ID = selectedFundId;
      form.append("formData", JSON.stringify(formData2));
      if (isCompanyUpdate) {
        const response = await axiosInstance.patch("/fund-review-data", form);
        if (response.data === "error") {
          toast.error("Error while updating Data.");
        } else {
          // setFieldData({});
          setIsSelected(false);
          toast.success("Data updated successfully");
        }
      } else {
        const response = await axiosInstance.post("/fund-review-data", form);
        if (response.data === "error") {
          toast.error("Error while updating Data.");
        } else {
          setIsCompanyUpdate(true);
          setIsSelected(false);
          toast.success("Data updated successfully");
        }
      }
    } catch (err) {
      toast.error("Error while updating Data.");
      console.error(err);
    } finally {
      setTimeout(() => {
        if (date) {
          const day = date.getDate();
          const month = date.getMonth() + 1; // Months are zero-based, so add 1
          const year = date.getFullYear();

          // Format the date as DD-MM-YYYY
          const formattedDate = `${day.toString().padStart(2, "0")}-${month
            .toString()
            .padStart(2, "0")}-${year}`;

          getData(selectedFundId, formattedDate);
          getRefData(selectedFundId, formattedDate);
          fetchData(formattedDate, selectedFundId);
        }
      }, 3000);
    }
  };

  const getData = async (fund_ID: string, reportingDate: string) => {
    try {
      const response = await axiosInstance.get(
        "/fund-review-extracted-data/" + fund_ID + "/" + reportingDate
      );
      let newData = { ...response.data.data[0] };
      delete newData.record_type;

      for (const key in newData) {
        if (Array.isArray(newData[key])) {
          if (
            newData[key].length > 0 &&
            typeof newData[key][0] === "object" &&
            !Array.isArray(newData[key][0])
          ) {
            const firstObj = newData[key][0];
            const firstKey = Object.keys(firstObj)[0]; // Get the first key of the first object
            newData[key] = firstObj[firstKey]; // Update the key to the value of the first key in the first object of the array
          } else {
            // If it's not an array of objects, or the array is empty, just use the first element
            newData[key] = newData[key][0] || "";
          }
        }
      }
      setExtractedData(newData);
    } catch (error) {
      console.error(error);
    }
  };
  const getFileNames = async () => {
    try {
      const response = await axiosInstance.get("/fund-names");

      const responseData = await response.data.data;
      const sortedData = responseData.sort(
        (a: { Fund_Name: string }, b: { Fund_Name: string }) => {
          return a.Fund_Name.toLowerCase().localeCompare(
            b.Fund_Name.toLowerCase()
          );
        }
      );
      setFileName(sortedData);
      // if (fundID) {
      //   setSelectedFundId(fundID);
      //   const selectedFund = responseData.find(
      //     (fund: any) => fund.fund_ID === fundID
      //   );
      //   setFundName(selectedFund.Fund_Name);
      // }
    } catch (error) {
      console.error(error);
    }
  };

  const handlePortfolioChanage = (key: string, value: string) => {
    setInputValue((prevInputValue: any) => ({
      ...prevInputValue,
      [key]: value,
    }));
  };

  const getRefData = async (fund_ID: string, Reporting_Date: string) => {
    try {
      const response = await axiosInstance.get(
        "/fund-review-ref-data/" + fund_ID + "/" + Reporting_Date
      );
      const companyData = response.data.data[0];

      const updatedStaticValue = staticValue.map((item) => {
        Object.keys(item).forEach((key) => {
          if (companyData.hasOwnProperty(key)) {
            item[key as keyof typeof item] = companyData[key];
          }
        });
        return item;
      });
      setStaticValue(updatedStaticValue);
    } catch (error) {
      console.error(error);
    }
  };
  function convertDate(dateString: string) {
    // Split the input date string by "-"
    const [day, month, year] = dateString.split("-");

    // Return the date in the format "YYYY-MM-DD"
    return `${year}-${month}-${day}`;
  }
  const fetchData = async (reportingDate: string, fund_ID: string) => {
    try {
      const response = await axiosInstance.get(
        "/getFundQtr/" + reportingDate + "/" + fund_ID
      );
      if (response.data === "error") {
        toast.error("Error while fetching Data.");
      } else {
        if (response.data.data.length === 0) {
          const responseData = updateFundQurterlyData(
            fund_ID,
            fundName,
            reportingDate,
            convertDate(reportingDate)
          );
          setInputValue(responseData[0]);
          setIsSelected(true);
          setIsCompanyUpdate(false);
        } else {
          const responseData = response.data.data[0];
          setInputValue(responseData);
          setIsCompanyUpdate(true);
          setIsSelected(true);
        }
      }
    } catch (err) {
      toast.error("Error while fetching Data.");
      console.error(err);
    }
  };

  return (
    <React.Fragment>
      <Helmet title="Blank" />
      <Divider my={6} />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Card mb={6}>
            <CardContent>
              <div style={{ display: "flex" }}>
                <label
                  style={{
                    marginRight: "10px",
                    marginTop: "10px",
                    fontWeight: "bold",
                  }}
                >
                  Select Fund :
                </label>
                <select
                  style={{
                    backgroundColor: "#f2f2f2",
                    marginRight: "20px",
                    color: "#333",
                    padding: "10px",
                    border: "1px solid #ccc",
                    borderRadius: "5px",
                    outline: "none",
                    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)",
                    width: "250px",
                    cursor: "pointer",
                  }}
                  value={selectedFundId}
                  onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                    setIsSelected(false);
                    const selectedIndex = e.target.selectedIndex - 1;
                    if (selectedIndex >= 0) {
                      setSelectedFundId(e.target.value);
                      const fundName = filename[selectedIndex].Fund_Name;
                      setFundName(fundName);
                    }
                  }}
                >
                  <option value="">Please select fund</option>
                  {filename.map((fileName, index) => {
                    return (
                      <option key={index} value={filename[index].fund_ID}>
                        {filename[index].Fund_Name}
                      </option>
                    );
                  })}
                </select>
                <DatePicker
                  label="Reporting Date"
                  format="dd/MM/yyyy"
                  value={date}
                  onChange={(newValue) => {
                    setIsSelected(false);
                    setDate(newValue);
                  }}
                />
                <Button
                  onClick={() => {
                    if (date && selectedFundId) {
                      const day = date.getDate();
                      const month = date.getMonth() + 1; // Months are zero-based, so add 1
                      const year = date.getFullYear();

                      // Format the date as DD-MM-YYYY
                      const formattedDate = `${day
                        .toString()
                        .padStart(2, "0")}-${month
                        .toString()
                        .padStart(2, "0")}-${year}`;
                      fetchData(formattedDate, selectedFundId);
                      getData(selectedFundId, formattedDate);
                      getRefData(selectedFundId, formattedDate);
                    } else {
                      alert("Please select fund and date");
                    }
                  }}
                  style={{
                    margin: "5px",
                    marginLeft: "20px",
                    backgroundColor: "#007bff",
                    borderRadius: "18px",
                  }}
                  variant="contained"
                >
                  Retrieve Data
                </Button>
              </div>

              <ThemeProvider theme={theme}>
                {isSelected
                  ? inputvalue && (
                      <div>
                        <div style={styles.buttonContainer}>
                          <Button
                            onClick={() => {
                              UpdateCompanyData();
                            }}
                            style={{
                              margin: "20px",
                              backgroundColor: "#007bff",
                              borderRadius: "18px",
                            }}
                            variant="contained"
                          >
                            {isCompanyUpdate ? "Update Fund" : "Add Fund"}
                          </Button>
                        </div>
                        <PortfolioReviewExtractedForm
                          pdf_link={fundPDF}
                          inputvalue={inputvalue}
                          handlePortfolioChange={handlePortfolioChanage}
                          constKey={portfolioReviewconstKey}
                          PortfolioKeyNames={PortfolioReviewKeyNames}
                          form={form}
                          staticValue={staticValue}
                          extractedData={extractedData}
                          keyOrder={portfolioReviewKeyOrder}
                          styles={styles}
                          runId={currentRunID}
                        />
                      </div>
                    )
                  : null}
              </ThemeProvider>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default PortfolioDataUploads;
