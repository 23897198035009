import React, { CSSProperties, ChangeEvent, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import GridDeleteIcon from "@mui/icons-material/Delete";

interface PreviousInvestDialogProps {
  open: boolean;
  onClose: () => void;
  previousInvestment: string[];
  onPreviousInvestChange: (
    index: number,
    event: ChangeEvent<HTMLInputElement>
  ) => void;
  onAddPreviousInvest: () => void;
  onRemovePreviousInvest: (index: number) => void;
}

const PreviousInvestDialog: React.FC<PreviousInvestDialogProps> = ({
  open,
  onClose,
  previousInvestment,
  onPreviousInvestChange,
  onAddPreviousInvest,
  onRemovePreviousInvest,
}) => {
  const styles: { [key: string]: CSSProperties } = {
    label: {
      fontWeight: "bold",
      padding: "10px",
      width: "20px", // Align the text to the right within the label cell
    },
    label2: {
      fontWeight: "bold",
      padding: "5px 20px",
    },
    input: {
      marginTop: "3px",
      verticalAlign: "top",
      padding: "10px",
      border: "1px solid #ccc",
      width: "200px",
      borderRadius: "4px", // Make input take full width of its cell
    },
    buttonContainer: {
      textAlign: "center",
      marginTop: "20px", // Space above the button
    },
    button: {
      padding: "12px 24px",
      backgroundColor: "#007bff",
      color: "#fff",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
    },
    button2: {
      marginLeft: "20px",
      padding: "12px 24px",
      backgroundColor: "#007bff",
      color: "#fff",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
    },
  };
  const [imageError, setImageError] = useState(false);
  const handleImageError = () => {
    setImageError(true);
  };
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Manage Fund Track Records</DialogTitle>
      <DialogContent>
        <table>
          <tbody>
            {previousInvestment.map((record, index) => (
              <React.Fragment key={index}>
                {/* Iterate over all fields in the IFundTrackRecord interface */}
                <tr>
                  <td style={styles.label}>Image{index + 1}:</td>
                  <td>
                    <input
                      type="file"
                      id={`profile_image_${index}`}
                      name="Imvestment Image"
                      onChange={(e) => onPreviousInvestChange(index, e)}
                      accept="image/jpeg, image/png"
                      style={{
                        marginLeft: "10px",
                      }}
                    />
                    {record && !imageError ? (
                      <img
                        style={{
                          padding: "5px",
                        }}
                        src={
                          typeof record === "string"
                            ? record
                            : URL.createObjectURL(record)
                        }
                        width="75px"
                        height="75px"
                        onError={handleImageError}
                        alt="Dynamic Content"
                        onLoad={
                          typeof record === "object"
                            ? (e) => URL.revokeObjectURL(e.currentTarget.src)
                            : undefined
                        }
                      />
                    ) : (
                      "No Image Found"
                    )}
                    <GridDeleteIcon
                      style={{
                        cursor: "pointer",
                        marginLeft: "10px",
                        marginTop: "10px",
                      }}
                      onClick={() => onRemovePreviousInvest(index)}
                    />
                  </td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </DialogContent>
      <DialogActions>
        <Button onClick={onAddPreviousInvest} color="primary">
          Add New Image
        </Button>
        <Button onClick={onClose} color="primary">
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PreviousInvestDialog;
