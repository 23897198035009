import {
  Typography,
  Grid,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Paper,
  Link,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import ImagePopup from "../../../components/AddData/ImagePop";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import IconButton from "@mui/material/IconButton";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import { NavLink } from "react-router-dom";

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
function WorkflowPage() {
  const [frontOffice, setFrontOffice] = useState([
    {
      name: "Portfolio Quarterly Report",
      path: "",
      visible: false,
      subitems: [
        {
          name: "Upload Quarterly Report PDF For AI Processing",
          path: "/AI_extract",
        },
        { name: "AI Data Update", path: "/update_script_data" },
      ],
    },
    {
      name: "Market Watch Report",
      path: "/update_marketWatch",
      visible: false,
    },
    {
      name: "Portfolio Review Form",
      path: "",
      visible: false,
      subitems: [
        {
          name: "Update Portfolio Review Data",
          path: "/update_PortfolioReviewData",
        },
        {
          name: "Top movers Historic Data Update",
          path: "/update_PortfolioReviewTable",
        },
      ],
    },
  ]);
  const toggleSubitemsVisibility = (index: number) => {
    const newFrontOffice = frontOffice.map((item, idx) => {
      if (idx === index) {
        return { ...item, visible: !item.visible };
      }
      return item;
    });
    setFrontOffice(newFrontOffice);
  };
  const togglebackSubitemsVisibility = (index: number) => {
    const newBackOffice = backOffice.map((item, idx) => {
      if (idx === index) {
        return { ...item, visible: !item.visible };
      }
      return item;
    });
    setbackOffice(newBackOffice);
  };

  const [backOffice, setbackOffice] = useState([
    {
      name: "Capital Call Form",
      path: "",
      visible: false,
      subitems: [
        {
          name: "Upload Capital Call PDF For AI Processing",
          path: "/AI_CapitalCall",
        },
        { name: "Capital Call Data AI Update", path: "/AI_CapitalCall_Update" },
        // { name: "Capital Call Data Update", path: "/update_CapitalCall" },
      ],
    },
    {
      name: "Distribution Form",
      path: "",
      visible: false,
      subitems: [
        {
          name: "Upload Distribution Form PDF For AI Processing",
          path: "AI_DisributionCall",
        },
        {
          name: "Distribution Data Update",
          path: "/AI_DistributionCall_Update",
        },
      ],
    },
    { name: "FOF Reports", path: "/Update_FOF", visible: false },
    {
      name: "Annex",
      path: "",
      visible: false,
      subitems: [
        {
          name: "Update Footnotes",
          path: "/Update_notes",
        },
      ],
    },
  ]);

  useEffect(() => {
    if (
      process.env.REACT_APP_NAME === "DEMO2" ||
      process.env.REACT_APP_NAME === "AfricInvest"
    ) {
      setFrontOffice([frontOffice[0]]);
      setbackOffice([backOffice[0]]);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <React.Fragment>
      <Helmet title="Blank" />
      <Helmet title="Workflow" />
      <Typography variant="h3" gutterBottom display="inline">
        Workflow Page
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Dashboard
        </Link>
        <Typography>WorkFlow</Typography>
      </Breadcrumbs>
      <Divider my={6} />
      {process.env.REACT_APP_NAME === "teralys" && (
        <Grid
          style={{
            display: "flex",
            width: "100%",
            height: "225px",
            overflow: "hidden",
            maxWidth: "1600px",
            // maxWidth: "800px",
          }}
        >
          <ImagePopup
            src="/static/img/unsplash/Workflow_normal.png"
            alt="Workflow Description"
          />
        </Grid>
      )}

      <Grid container spacing={6}>
        <Grid
          item
          xs={12}
          style={{
            maxWidth: "1600px",
          }}
        >
          <Paper style={{ marginTop: "15px", padding: "1rem" }}>
            <Typography variant="h6">Front Office</Typography>
            <Table>
              <TableBody>
                {frontOffice.map((form, index) => (
                  <React.Fragment key={index}>
                    <TableRow
                      onClick={
                        form.subitems
                          ? () => toggleSubitemsVisibility(index)
                          : undefined
                      }
                      style={{
                        cursor: form.subitems ? "pointer" : "default",
                      }}
                    >
                      <TableCell
                        style={{
                          paddingLeft: form.subitems ? "0px" : "20px",
                          textAlign: "left",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          flex: 1,
                        }}
                      >
                        {form.subitems && (
                          <>
                            {form.visible ? (
                              <IconButton
                                onClick={() => toggleSubitemsVisibility(index)}
                              >
                                <RemoveCircleOutlineIcon />
                              </IconButton>
                            ) : (
                              <IconButton
                                onClick={() => toggleSubitemsVisibility(index)}
                              >
                                <AddCircleOutlineIcon />
                              </IconButton>
                            )}
                          </>
                        )}
                        <Typography
                          variant="subtitle2"
                          style={{ display: "inline" }}
                        >
                          {index + 1}. {form.name}
                        </Typography>
                      </TableCell>
                      <TableCell
                        style={{
                          width: "10%",
                          textAlign: "center",
                          paddingRight: "16px",
                        }}
                      >
                        {form.path && (
                          <Button
                            variant="contained"
                            style={{
                              backgroundColor: "#007bff",
                              borderRadius: "18px",
                            }}
                            onClick={() => {
                              window.location.href = form.path;
                            }}
                          >
                            View
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                    {form.subitems &&
                      form.visible &&
                      form.subitems.map((subitem, subIndex) => (
                        <TableRow
                          key={subIndex}
                          style={{
                            paddingLeft: "50px",
                          }}
                        >
                          <TableCell
                            style={{
                              textAlign: "left",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              flex: 1,
                              paddingLeft: "50px",
                            }}
                          >
                            <Typography variant="subtitle2">
                              {index + 1}.{subIndex + 1} {subitem.name}
                            </Typography>
                          </TableCell>
                          <TableCell
                            style={{
                              width: "10%",
                              textAlign: "center",
                              paddingRight: "16px",
                            }}
                          >
                            <Button
                              variant="contained"
                              style={{
                                backgroundColor: "#007bff",
                                borderRadius: "18px",
                              }}
                              onClick={() => {
                                window.location.href = subitem.path;
                              }}
                            >
                              View
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </Paper>
          <Paper style={{ marginTop: "15px", padding: "1rem" }}>
            <Typography variant="h6">Back Office</Typography>
            <Table>
              <TableBody>
                {backOffice.map((form, index) => (
                  <React.Fragment key={index}>
                    <TableRow
                      onClick={
                        form.subitems
                          ? () => togglebackSubitemsVisibility(index)
                          : undefined
                      }
                      style={{
                        cursor: form.subitems ? "pointer" : "default",
                      }}
                    >
                      <TableCell
                        style={{
                          paddingLeft: form.subitems ? "0px" : "20px",
                          textAlign: "left",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          flex: 1,
                        }}
                      >
                        {form.subitems && (
                          <>
                            {form.visible ? (
                              <IconButton
                                onClick={() =>
                                  togglebackSubitemsVisibility(index)
                                }
                              >
                                <RemoveCircleOutlineIcon />
                              </IconButton>
                            ) : (
                              <IconButton
                                onClick={() =>
                                  togglebackSubitemsVisibility(index)
                                }
                              >
                                <AddCircleOutlineIcon />
                              </IconButton>
                            )}
                          </>
                        )}
                        <Typography
                          variant="subtitle2"
                          style={{ display: "inline" }}
                        >
                          {index + 1}. {form.name}
                        </Typography>
                      </TableCell>
                      <TableCell
                        style={{
                          width: "10%",
                          textAlign: "center",
                          paddingRight: "16px",
                        }}
                      >
                        {form.path && (
                          <Button
                            variant="contained"
                            style={{
                              backgroundColor: "#007bff",
                              borderRadius: "18px",
                            }}
                            onClick={() => {
                              window.location.href = form.path;
                            }}
                          >
                            View
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                    {form.subitems &&
                      form.visible &&
                      form.subitems.map((subitem, subIndex) => (
                        <TableRow
                          key={subIndex}
                          style={{
                            paddingLeft: "50px",
                          }}
                        >
                          <TableCell
                            style={{
                              textAlign: "left",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              flex: 1,
                              paddingLeft: "50px",
                            }}
                          >
                            <Typography variant="subtitle2">
                              {index + 1}.{subIndex + 1} {subitem.name}
                            </Typography>
                          </TableCell>
                          <TableCell
                            style={{
                              width: "10%",
                              textAlign: "center",
                              paddingRight: "16px",
                            }}
                          >
                            {subitem.path && (
                              <Button
                                variant="contained"
                                style={{
                                  backgroundColor: "#007bff",
                                  borderRadius: "18px",
                                }}
                                disabled={
                                  process.env.REACT_APP_NAME === "AfricInvest"
                                }
                                onClick={() => {
                                  window.location.href = subitem.path;
                                }}
                              >
                                View
                              </Button>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default WorkflowPage;
