import { useState, useRef } from "react";
import axiosInstance from "../../utils/axios/axios.instance";
import CircularWithValueLabel from "../../components/CricularLoader";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
function LongSummary() {
  const [loader, setLoader] = useState(0);
  const [files, setFiles] = useState<File[]>([]);
  const [data, setData] = useState("");
  const [summary, setSummary] = useState("");
  const inputFileRef = useRef<HTMLInputElement | null>(null);

  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = Array.from(event.target.files || []); // Use type guard for files
    setFiles((prevFiles) => [...prevFiles, ...selectedFiles]); // Add the selected files to existing files
  };

  const onDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const droppedFiles = Array.from(event.dataTransfer.files); // Convert FileList to Array
    setFiles((prevFiles) => [...prevFiles, ...droppedFiles]); // Add the dropped files to existing files
  };
  const getSummary = async (time: String) => {
    try {
      const response = await axiosInstance.get("/getSummary", {
        params: {
          timestamp: time,
        },
      });
      await setSummary(response.data);
      setLoader(1);
      setData("Preparing the Excel file.... ");
      // Assuming you have the Base64 encoded ZIP string in a variable called `zipString`
    } catch (err) {
      console.error(err);
    }
  };
  const handleDeleteFile = (fileIndex: number) => {
    const newFiles = files.filter((_, index) => index !== fileIndex);
    setFiles(newFiles);
  };
  const runscript = async () => {
    // setData();
    //     if (file) {
    //   let time = new Date().toISOString();
    //   time = time.replace(/:/g, '-');
    //   setData("Preparing Summary... ");
    //   setTimeout(() => {
    //     getSummary(time);
    //   }, 5000);
    //   setTimeout(() => {
    //     getData(time,file.name.slice(0, -4));
    //   }, 30000);
    //   try {
    //     const formData = new FormData();
    //     formData.append("pdf", file); // 'pdf' is the name the backend will use to retrieve this file
    //     const response = await axiosInstance.post("/runscript", formData,{
    //       headers: { "Content-Type": "multipart/form-data" },
    //       params: { timestamp: time }
    //     });
    //     // Assuming you have the Base64 encoded ZIP string in a variable called `zipString`
    //   } catch (err) {
    //     console.error(err);
    //   }
    // } else {
    //   console.error("No file selected.");
    // }
  };
  const onSave = (file: File) => {

  };
  return (
    <div style={{ alignContent: "center", alignSelf: "centers" }}>
      This is the page where you can upload multiple pdfs and get the summary of
      all the pdfs in summary section.
      <div
        style={{
          marginLeft: "25%",
          width: "50%",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            marginTop: "50px",
            border: "2px dashed gray",
            padding: "20px",
            cursor: "pointer",
            textAlign: "center",
          }}
          onDragOver={(event) => event.preventDefault()}
          onDrop={onDrop}
          onClick={() => inputFileRef.current?.click()} // 2. Use the ref to click the input
        >
          {files.length > 0
            ? `${files.length} PDFs selected`
            : "Drag & Drop or Click to Select Files"}
          <input
            type="file"
            style={{ display: "none" }}
            onChange={onFileChange}
            onClick={(e: any) => (e.target.value = "")}
            ref={inputFileRef}
            multiple // This will allow multiple files to be selected
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "15px",
            marginTop: "10px",
          }}
        >
          {files.map((file, index) => (
            <div style={{ display: "flex" }}>
              <div
                key={index}
                style={{
                  width: "95%",
                  overflow: "hidden",
                  border: "1px solid #e0e0e0",
                  borderRadius: "5px",
                  display: "flex",
                  boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.1)",
                }}
              >
                <div
                  style={{
                    marginLeft: "10px",
                    marginTop: "10px",
                    fontSize: "5px",
                    background:
                      "url(your-pdf-icon-url-here) center/cover no-repeat",
                  }}
                ></div>
                <span
                  style={{
                    fontSize: "12px",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                >
                  {file.name}
                </span>
              </div>
              <div style={{ width: "5%" }}>
                <IconButton
                  aria-label="delete"
                  size="medium"
                  onClick={() => handleDeleteFile(index)}
                >
                  <DeleteIcon fontSize="inherit" />
                </IconButton>
              </div>
            </div>
          ))}
        </div>

        <button
          onClick={runscript}
          style={{
            marginLeft: "40%",
            marginTop: "20px",
            backgroundColor: "#673AB7",
            color: "white",
            border: "none",
            padding: "12px 24px",
            borderRadius: "4px",
            fontSize: "16px",
            fontWeight: "500",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            transition: "transform 0.2s, box-shadow 0.2s",
            cursor: "pointer",
            outline: "none",
          }}
        >
          Process
        </button>

        <div
          style={{
            marginLeft: "33%",
            marginTop: "1rem",
            marginBottom: "10%",
            display: "flex",
          }}
        >
          <div style={{ paddingTop: "10px", marginRight: "4%" }}>{data}</div>
          <div>
            {" "}
            {loader > 0 && (
              <CircularWithValueLabel initialProgress={loader} />
            )}{" "}
          </div>{" "}
        </div>
      </div>
      {summary && (
        <div
          style={{
            backgroundColor: "white",
            border: "1px solid #e0e0e0",
            padding: "15px",
            textAlign: "justify",
          }}
        >
          <b>Summary: </b>
          {summary}
        </div>
      )}
    </div>
  );
}

export default LongSummary;
