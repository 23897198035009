import {Helmet} from "react-helmet-async";
import {Card as MuiCard, CardContent, Divider as MuiDivider, Grid, Typography} from "@mui/material";
import React, {useState} from "react";
import styled from "@emotion/styled";
import {spacing} from "@mui/system";
import AddDistributionFileForm from "../../../pages/pages/workflow/distribution_call/addDistributionFileForm";
import DistributionUpdateForm from "../../../pages/pages/workflow/distribution_call/distributionUpdateForm";

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);

const DistributionTool = () => {
    const [currentTool, setCurrentTool] = useState<number>(0);
    const tools = [
        {
            id: 1,
            name: "Upload File",
        },
        {
            id: 2,
            name: "Update Data",
        },
    ];

    return (
        <React.Fragment>
            <Helmet title="Distribution Tool" />

            <Typography variant="h3">Distribution Tool</Typography>
            <Divider my={6} />

            <Typography
                style={{
                    textAlign: "justify",
                    marginBottom: "20px",
                    width: "80%",
                }}
            >
                This tool aids with the distribution related field extraction.
            </Typography>

            <Grid container spacing={6}>
                <Grid item xs={12}>
                    <Card mb={6}>
                        <CardContent>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    flexWrap: "wrap",
                                    justifyContent: "flex-start",
                                    marginBottom: "60px",
                                    cursor: "pointer",
                                }}
                            >
                                {tools.map((tool) => {
                                    return (
                                        <div
                                            key={tool.id}
                                            onClick={() => {
                                                setCurrentTool(tool.id);
                                            }}
                                            style={{
                                                minWidth: "200px",
                                                height: "60px",
                                                fontSize: "1.1rem",
                                                border: "3px solid",
                                                marginRight: "20px",
                                                marginTop: "20px",
                                                backgroundColor: "#1B2430",
                                                color: "#fff",
                                                fontWeight: "bold",
                                                borderColor:
                                                    tool.id === currentTool ? "#C5585C" : "transparent",
                                                opacity: tool.id === currentTool ? "1" : "0.5",
                                                borderRadius: "5px",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    width: "100%",
                                                    padding: "6px 10px",
                                                }}
                                            >
                                                <div style={{ marginTop: "4px" }}>{tool.name}</div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                            {currentTool === 1 && <AddDistributionFileForm isTool={true} handleNext={() => {setCurrentTool(currentTool+1)}}/>}
                            {currentTool === 2 && <DistributionUpdateForm />}
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

export default DistributionTool;
