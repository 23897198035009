import { useState, useRef, useEffect } from "react";
import {
  FormControl,
  Grid,
  Divider as MuiDivider,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import { Helmet } from "react-helmet-async";
import { toast } from "react-toastify";
import axiosInstance from "../../../utils/axios/axios.instance";
import DuplicateModal from "../../Workflow/ModalDuplicateFile";
import ModalPortfolioReview from "./ModalForPortfolioReview";
const Divider = styled(MuiDivider)(spacing);
const PortfolioFileUploads = () => {
  const { user } = useAuth0();
  const [userEmail, setUserEmail] = useState("");
  const [file, setFile] = useState<File | null>(null);
  const [fundID, setfundID] = useState("");
  const [fundKeys, setFundKeys] = useState<{ [key: string]: any }>({});
  const [fund_name, setFund_name] = useState("");
  const inputFileRef = useRef<HTMLInputElement | null>(null);
  const [fundIDs, setfundIDs] = useState([]);
  const quarters = [
    { name: "Q1", value: "03-31" },
    { name: "Q2", value: "06-30" },
    { name: "Q3", value: "09-30" },
    { name: "Q4", value: "12-31" },
  ];
  const [selectedQuarter, setSelectedQuarter] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [years, setYears] = useState<number[]>([]);
  const getFundNames = async () => {
    const response = await axiosInstance.get("/fund-names");
    const responseData = response.data.data;
    const sortedData = responseData.sort(
      (a: { Fund_Name: string }, b: { Fund_Name: string }) => {
        return a.Fund_Name.toLowerCase().localeCompare(
          b.Fund_Name.toLowerCase()
        );
      }
    );
    setfundIDs(sortedData);
    setFund_name(sortedData[0]["Fund_Name"]);
    setfundID(sortedData[0]["fund_ID"]);
    let keys: { [key: string]: any } = {};
    // eslint-disable-next-line array-callback-return
    sortedData.map((fund: { [x: string]: any }) => {
      keys[fund["fund_ID"]] = fund["Fund_Name"];
    });
    setFundKeys(keys);
  };
  useEffect(() => {
    if (user?.email) {
      setUserEmail(user.email);
    }
    const dynamicYears = generateYears(2000);
    setYears(dynamicYears);
    getFundNames();
    setCurrentDate();
    // eslint-disable-next-line
  }, []);
  const setCurrentDate = () => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;
    let currentQuarter = "";
    let currentYear = currentDate.getFullYear();

    if (currentMonth >= 1 && currentMonth <= 3) {
      currentQuarter = "12-31";
      currentYear = currentYear - 1;
    } else if (currentMonth >= 4 && currentMonth <= 6) {
      currentQuarter = "03-31";
    } else if (currentMonth >= 7 && currentMonth <= 9) {
      currentQuarter = "06-30";
    } else {
      currentQuarter = "09-30";
    }
    setSelectedQuarter(currentQuarter);
    setSelectedYear(currentYear.toString());
  };
  const resetData = () => {
    setIsButtonDisabled(false);
    setFile(null);
  };
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const handleOpenModal = () => {
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };
  const uploadAnother = () => {
    resetData();
    handleCloseModal();
  };
  const model2Close = () => {
    resetData();
    setShowModal2(false);
  };
  const sameFile = () => {
    resetData();
    model2Close();
  };
  const [fileName, setFileName] = useState("");
  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setFile(event.target.files[0]);
      setFileName(event.target.files[0].name.slice(0, -4));
    }
  };
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const runscript = async () => {
    setIsButtonDisabled(true);
    setTimeout(() => {
      setIsButtonDisabled(false);
    }, 4000);
    if (file) {
      let time = new Date().toISOString();
      time = time.replace(/:/g, "-");
      try {
        const formData = new FormData();
        const name = file.name.slice(0, -4);
        formData.append("pdf", file); // 'pdf' is the name the backend will use to retrieve this file
        formData.append("timestamp", time);
        formData.append("fund_ID", fundID);
        formData.append("file_name", name);
        formData.append("email", userEmail);
        formData.append("date", selectedYear + "-" + selectedQuarter);
        handleOpenModal();
        await axiosInstance
          .post("/portfolio-review-extraction", formData, {
            headers: { "Content-Type": "multipart/form-data" },
            params: {
              timestamp: time,
              name: name,
            },
          })
          .then((resp) => {
            if (resp.data.data === "Duplicate file.") {
              setFileName(resp.data.azureURL.data);
            } else {
              handleOpenModal();
            }
          });
      } catch (err) {
        toast.error("Error Processing File. Please try again later.");
      }
    } else {
      toast.error("Please select a file to process");
    }
  };
  const onDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (
      event.dataTransfer.items &&
      event.dataTransfer.items[0].kind === "file"
    ) {
      const droppedFile = event.dataTransfer.items[0].getAsFile();
      setFile(droppedFile);
    }
  };
  const generateYears = (startYear: number): number[] => {
    const currentYear = new Date().getFullYear();
    const years: number[] = [];
    for (let year = startYear; year <= currentYear; year++) {
      years.push(year);
    }
    return years.reverse();
  };
  const handleQuarterChange = (event: any) => {
    setSelectedQuarter(event.target.value);
  };
  const handleYearChange = (event: any) => {
    setSelectedYear(event.target.value);
  };
  const handleFundChange = (event: any) => {
    setFund_name(fundKeys[event.target.value]);
    setfundID(event.target.value);
  };
  return (
    <div style={{ alignContent: "center", alignSelf: "centers" }}>
      <Helmet title="Potrfolio File Uploads" />
      <Divider my={6} />
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignContent: "center",
          alignSelf: "centers",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            maxWidth: "800px",
            width: "50%",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography
                variant="subtitle2"
                fontSize="15px"
                style={{
                  padding: "10px",
                }}
              >
                Select fund name:
              </Typography>
              <FormControl fullWidth>
                <InputLabel>Funds</InputLabel>
                <Select
                  value={fundID}
                  style={{ maxWidth: "300px" }}
                  onChange={handleFundChange}
                  label="Funds"
                >
                  {fundIDs.map((fund) => (
                    <MenuItem key={fund["Fund_Name"]} value={fund["fund_ID"]}>
                      {fund["Fund_Name"]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography
                variant="subtitle2"
                fontSize="15px"
                style={{
                  padding: "10px",
                }}
              >
                Select reporting quarter and year:
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel>Quarter</InputLabel>
                    <Select
                      value={selectedQuarter}
                      onChange={handleQuarterChange}
                      label="Quarter"
                    >
                      {quarters.map((quarter) => (
                        <MenuItem key={quarter.name} value={quarter.value}>
                          {quarter.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                {/* Year Selector */}
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel>Year</InputLabel>
                    <Select
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 300, // Adjust max height to your preference
                          },
                        },
                      }}
                      defaultValue={selectedYear}
                      value={selectedYear}
                      onChange={handleYearChange}
                      label="Year"
                    >
                      {years.map((year) => (
                        <MenuItem key={year} value={year}>
                          {year}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <div
            style={{
              // marginLeft: "25%",
              width: "100%",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                marginTop: "50px",
                border: "2px dashed gray",
                padding: "20px",
                cursor: "pointer",
                textAlign: "center",
              }}
              onDragOver={(event) => event.preventDefault()}
              onDrop={onDrop}
              onClick={() => inputFileRef.current?.click()} // 2. Use the ref to click the input
            >
              {file ? file.name : "Drag & Drop or Click to Select a File"}
              <input
                type="file"
                style={{ display: "none" }}
                onChange={onFileChange}
                onClick={(e: any) => (e.target.value = "")}
                ref={inputFileRef} // Attach the ref to the input
              />
            </div>
            <div
              style={{
                width: "100%",
                marginTop: "20px",
                cursor: "pointer",
                textAlign: "center",
              }}
            >
              <TextField
                fullWidth
                value={userEmail}
                onChange={(e) => setUserEmail(e.target.value)}
                // inputRef={inputEmail}
                type="email"
                label="Email Address"
                variant="outlined"
                placeholder="email@example.com"
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              <button
                disabled={isButtonDisabled}
                onClick={() => {
                  if (selectedQuarter && selectedYear) {
                    runscript();
                  } else {
                    toast.error("Please select a quarter and year");
                  }
                }}
                style={{
                  backgroundColor: isButtonDisabled ? "#9E9E9E" : "#007bff",
                  color: "white",
                  border: "none",
                  padding: "12px 24px",
                  borderRadius: "18px",
                  fontSize: "16px",
                  fontWeight: "500",
                  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                  transition: "transform 0.2s, box-shadow 0.2s",
                  cursor: isButtonDisabled ? "default" : "pointer",
                  outline: "none",
                  marginRight: "10px", // Add some space between the buttons
                }}
              >
                Process
              </button>
              <button
                onClick={resetData}
                style={{
                  backgroundColor: "#007bff",
                  color: "white",
                  border: "none",
                  padding: "12px 24px",
                  borderRadius: "18px",
                  fontSize: "16px",
                  fontWeight: "500",
                  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                  transition: "transform 0.2s, box-shadow 0.2s",
                  cursor: "pointer",
                  outline: "none",
                }}
              >
                Reset
              </button>
            </div>
          </div>
        </div>
        <ModalPortfolioReview
          email={userEmail}
          handleClose={uploadAnother}
          fundName={fund_name}
          fileName={fileName}
          show={showModal}
          handleUploadAnother={uploadAnother}
          isPortfolio={false}
        />
        <DuplicateModal
          handleClose={model2Close}
          fileName={fileName}
          show={showModal2}
          sameFile={sameFile}
        />
      </div>
    </div>
  );
};

export default PortfolioFileUploads;
