import React from "react";
import { Helmet } from "react-helmet-async";
import {
  Divider as MuiDivider,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Typography,
} from "@mui/material";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import { NavLink } from "react-router-dom";
import DistributionUpdateForm from "./distributionUpdateForm";

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

function DistributionCallAIUpdate() {

  return (
    <React.Fragment>
      <Helmet title="Blank" />
      <Typography variant="h3" gutterBottom display="inline">
        AI Extracted Data
      </Typography>
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Dashboard
        </Link>
        <Link component={NavLink} to="/workFlow">
          WorkFlow
        </Link>
        <Typography>Distribution Call update</Typography>
      </Breadcrumbs>
      <Divider my={6} />
      <DistributionUpdateForm />
    </React.Fragment>
  );
}

export default DistributionCallAIUpdate;
