import React, { RefObject, useState } from "react";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import PdfViewerModal from "../../add_updateForm/PDFModal";
import TruncateText from "../../UpdateScript/component/dialog";

interface PortfolioFormProps {
  form: RefObject<HTMLFormElement>;
  pdf_link: string;
  staticValue: Array<Record<string, any>>;
  extractedData: Record<string, any>;
  inputvalue: Record<string, any>;
  handlePortfolioChange: (key: string, value: any) => void;
  constKey: string[];
  keyOrder: string[];
  PortfolioKeyNames: Record<string, string>;
  styles: Record<string, React.CSSProperties>;
  runId: string;
}

const PortfolioReviewExtractedForm: React.FC<PortfolioFormProps> = ({
  form,
  staticValue,
  extractedData,
  pdf_link,
  inputvalue,
  handlePortfolioChange,
  constKey,
  keyOrder,
  PortfolioKeyNames,
  styles,
  runId,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [pageNumber, setPageNumber] = useState(1);
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const renderRow = (key: string, index: number) => {
    const file = extractedData;
    const reference = staticValue[0];
    if (key === "_id") return null;

    const label = PortfolioKeyNames[key] || key.replace(/_/g, " ");

    return (
      <tr
        key={index}
        style={{
          paddingTop: "30px",
          borderBottom: "1px solid #cccccc",
          backgroundColor: index % 2 === 0 ? "#f2f2f2" : "#FFFFFF",
        }}
      >
        <td style={{ ...styles.label, paddingBottom: "10px", width: "20%" }}>
          {label}
        </td>
        <td>
          <TextField
            multiline
            maxRows={6}
            minRows={
              key === "Reporting_Date" || key === "portfolio_companyName"
                ? 1
                : 3
            }
            id={index.toString()}
            name={key}
            style={{
              width: "100%",
              textAlign: "justify",
              marginBottom: "10px",
              background: "#fff",
              borderColor: "#007bff",
              borderWidth: "2px",
              borderRadius: "4px",
              margin: "5px",
            }}
            value={inputvalue[key]}
            type="text"
            onChange={(e) => handlePortfolioChange(key, e.target.value)}
            disabled={constKey.includes(key)}
          />
        </td>

        {file[key] &&
        (typeof file[key] === "string" || typeof file[key] === "number") ? (
          <>
            <td
              style={{
                ...styles.label,
                textAlign: "center",
                paddingBottom: "10px",
                width: "25%",
              }}
            >
              {<TruncateText text={file[key]} maxWords={40} />}
              {!constKey.includes(key) && (
                <IconButton
                  aria-label="content copy"
                  size="small"
                  onClick={() => handlePortfolioChange(key, file[key])}
                >
                  <ContentCopyIcon fontSize="inherit" />
                </IconButton>
              )}
            </td>
          </>
        ) : (
          <>
            <td></td>
          </>
        )}
        <td
          style={{
            ...styles.label,
            textAlign: "center",
            color: "grey",
            paddingBottom: "10px",
            width: "25%",
          }}
        >
          {reference[key] === "" ? (
            "-"
          ) : (
            <TruncateText text={reference[key]} maxWords={40} />
          )}
          {reference[key] && !constKey.includes(key) && (
            <IconButton
              aria-label="content copy"
              size="small"
              onClick={() => handlePortfolioChange(key, reference[key])}
            >
              <ContentCopyIcon fontSize="inherit" />
            </IconButton>
          )}
        </td>
      </tr>
    );
  };

  return (
    <form ref={form} onSubmit={(e) => e.preventDefault()}>
      <div>
        <table style={{ textAlign: "left", width: "100%" }}>
          <thead>
            <tr key="firstRow">
              <td colSpan={5}>
                <div
                  style={{
                    padding: "10px",
                    borderBottom: "2px solid #ccc",
                    backgroundColor: "#f9f9f9", // Light background color
                    textAlign: "center", // Center the title
                  }}
                >
                  <h3
                    style={{
                      margin: "0",
                      padding: "0",
                      color: "#333",
                      fontSize: "18px",
                      fontWeight: "bold",
                    }}
                  >
                    Dynamic Content
                  </h3>
                </div>
              </td>
            </tr>
            <tr>
              <th style={{ ...styles.tableLabel, width: "20%" }}>Key name</th>
              <th
                style={{
                  ...styles.tableLabel,
                  textAlign: "center",
                  width: "35%",
                }}
              >
                Update value manually
              </th>
              <th
                style={{
                  ...styles.tableLabel,
                  textAlign: "center",
                  width: "20%",
                }}
              >
                AI extracted value
              </th>

              <th
                style={{
                  ...styles.tableLabel,
                  color: "grey",
                  textAlign: "center",
                  width: "25%",
                }}
              >
                Previous Entry
              </th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(staticValue[0])
              .filter(
                (key) =>
                  key !== "_id" &&
                  key !== "run_id" &&
                  key !== "created_At" &&
                  key !== "record_type" &&
                  key !== "fund_ID" &&
                  key !== "company_ID" &&
                  !key.includes("pageNumber")
              )
              .sort((a, b) => keyOrder.indexOf(a) - keyOrder.indexOf(b))
              .map((key, index) => renderRow(key, index))}
          </tbody>
        </table>
      </div>
      <div>
        <PdfViewerModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          pdfUrl={pdf_link} // Replace with your actual PDF file URL
          pageNumber={pageNumber}
        />
      </div>
    </form>
  );
};

export default PortfolioReviewExtractedForm;
