import React, {
  CSSProperties,
  ChangeEvent,
  FormEvent,
  ReactElement,
  useEffect,
  useState,
} from "react";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import GridDeleteIcon from "@mui/icons-material/Delete";
import styled from "@emotion/styled";
import DashboardLayout from "../../../../layouts/Dashboard";
import { Helmet } from "react-helmet-async";
import {
  Box,
  Button,
  CardContent,
  Grid,
  Card as MuiCard,
  Divider as MuiDivider,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  DialogContentText,
} from "@mui/material";
import { spacing } from "@mui/system";
import { NavLink } from "react-router-dom";
import {
  generateYears,
  getPreviousQuarterEndDate,
} from "../../../../utils/dateUtil";
import axiosInstance from "../../../../utils/axios/axios.instance";
import { toast } from "react-toastify";
import imageCompression from "browser-image-compression";
import { t } from "i18next";
import { set } from "date-fns";

const Card = styled(MuiCard)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Divider = styled(MuiDivider)(spacing);
const form = React.createRef<HTMLFormElement>();
interface Note {
  _id: string;
  appendix_Name: string;
  note_Number: number;
  note_data: string;
  note_Image: string;
  Reporting_Date: string;
  appendix_ID: string;
}
function UpdateNotes() {
  const quarters = [
    { name: "Q1", value: "31-03" },
    { name: "Q2", value: "30-06" },
    { name: "Q3", value: "30-09" },
    { name: "Q4", value: "31-12" },
  ];
  const Appendix = [
    { name: "Appendix A", value: "A" },
    { name: "Appendix B", value: "B" },
    { name: "Appendix C", value: "C" },
    { name: "Appendix D", value: "D" },
    { name: "Appendix E", value: "E" },
    { name: "Appendix F", value: "F" },
    { name: "Appendix G", value: "G" },
    { name: "Appendix H", value: "H" },
    { name: "Appendix I", value: "I" },
  ];
  const styles: { [key: string]: CSSProperties } = {
    tableLabel: {
      fontWeight: "bold",
      padding: "10px",
      textAlign: "left",
      fontSize: "15px",
    },
    label: {
      fontWeight: "bold",
      padding: "10px",
      textAlign: "left", // Align the text to the right within the label cell
    },
    input: {
      padding: "10px",
      border: "1px solid #ccc",
      borderRadius: "4px",
      width: "70%", // Make input take full width of its cell
    },
    select: {
      display: "flex", // Make select fill the cell
      backgroundColor: "#f2f2f2", // Background color
      color: "#333", // Text color
      padding: "10px", // Padding
      border: "1px solid #ccc", // Border
      borderRadius: "5px", // Border radius
      outline: "none", // Remove outline when selected
      boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)", // Box shadow
      width: "200px", // Width
      cursor: "pointer", // Cursor style
    },
    defaultValue: {
      padding: "10px", // Add padding for aesthetic spacing
      marginLeft: "20px", // Space from the input cell
    },
    buttonContainer: {
      textAlign: "center",
      marginTop: "20px", // Space above the button
    },
    button: {
      padding: "12px 24px",
      backgroundColor: "#007bff",
      color: "#fff",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
    },
  };

  const [selectedQuarter, setSelectedQuarter] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [years, setYears] = useState<number[]>([]);
  const [selectedappendix, setselectedappendix] = useState("A");
  const [open, setOpen] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [isdata, setisdata] = useState(false);
  const [notesData, setNotesData] = useState<Note[]>([]);
  const [isUpdate, setisUpdate] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [actionType, setActionType] = useState("");
  const [noteIndex, setNoteIndex] = useState<number | null>(null);

  const handleClickOpen = (image: any) => {
    setSelectedImage(image);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedImage("");
  };

  const handleAddDataManually = () => {
    const maxNoteNumber = notesData.reduce(
      (max, note) => Math.max(max, note.note_Number),
      0
    );
    const newNote = {
      _id: "",
      appendix_Name: selectedappendix,
      note_Number: maxNoteNumber + 1,
      note_data: "",
      note_Image: "",
      Reporting_Date: `${selectedQuarter}-${selectedYear}`,
      appendix_ID: "",
    };
    setNotesData([...notesData, newNote]);
    setisdata(true);
    setShowDialog(false);
  };

  const handlePreviousRetrival = () => {
    getListofPreviousNotes(
      selectedappendix,
      selectedQuarter + "-" + selectedYear
    );
    setShowDialog(false);
  };

  const handleQuarterChange = (event: any) => {
    setisdata(false);
    setSelectedQuarter(event.target.value);
  };

  const handleYearChange = (event: any) => {
    setisdata(false);
    setSelectedYear(event.target.value);
  };
  const handleAppendixChanage = (event: any) => {
    setisdata(false);
    setselectedappendix(event.target.value);
  };

  const getLatestQuarterAndYear = () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1;
    let quarter;
    if (currentMonth <= 3) {
      quarter = "31-03";
    } else if (currentMonth <= 6) {
      quarter = "30-06";
    } else if (currentMonth <= 9) {
      quarter = "30-09";
    } else {
      quarter = "31-12";
    }

    return { latestQuarter: quarter, latestYear: currentYear };
  };
  const handleDescriptionChange = (index: number, newDescription: string) => {
    const updatedNotes = [...notesData];
    updatedNotes[index].note_data = newDescription;
    setNotesData(updatedNotes);
  };

  const handleDeleteNote = (index: number, NoteNumber: string) => {
    if (isUpdate) {
      deleteNote(NoteNumber);
    } else {
      const updatedNotes = notesData.filter((_, i) => i !== index);
      setNotesData(updatedNotes);
      toast.success("Notes removed successfully.");
    }
  };

  const handleImageChange = async (
    index: number,
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      try {
        let options = {
          maxSizeMB: 0.3, // Set max size to 0.3 MB (300 KB)
          maxWidthOrHeight: 1920,
          useWebWorker: true,
        };
        let compressedFile = await imageCompression(file, options);
        let quality = 0.9; // Start with 90% quality

        // Loop to further compress if needed
        while (compressedFile.size > 0.3 * 1024 * 1024 && quality > 0.1) {
          options = {
            maxSizeMB: 0.3,
            maxWidthOrHeight: 1920,
            useWebWorker: true,
          };
          compressedFile = await imageCompression(file, options);
          quality -= 0.1; // Decrease quality by 10%
        }

        const reader = new FileReader();
        reader.onloadend = () => {
          const updatedNotes = [...notesData];
          updatedNotes[index].note_Image = reader.result as string;
          setNotesData(updatedNotes);
        };
        reader.readAsDataURL(compressedFile);
      } catch (error) {
        console.error("Error compressing the image:", error);
      }
    }
  };
  const openDialog = (action: string, index: number | null = null) => {
    setActionType(action);
    setNoteIndex(index);
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
    setActionType("");
    setNoteIndex(null);
  };

  const confirmAction = () => {
    if (actionType === "addUpdate") {
      addUpdateNotes();
    } else if (actionType === "delete" && noteIndex !== null) {
      handleDeleteNote(noteIndex, notesData[noteIndex].note_Number.toString());
    }
    closeDialog();
  };
  const handleAddNote = () => {
    const maxNoteNumber = notesData.reduce(
      (max, note) => Math.max(max, note.note_Number),
      0
    );
    setNotesData([
      ...notesData,
      {
        _id: "",
        appendix_Name: selectedappendix,
        note_Number: maxNoteNumber + 1,
        note_data: "",
        note_Image: "",
        Reporting_Date: `${selectedQuarter}-${selectedYear}`,
        appendix_ID: "",
      },
    ]);
  };

  const getListOfNotes = async (appendix: string, reportingDate: string) => {
    setNotesData([]);
    try {
      const response = await axiosInstance.get(
        `/appendix-foot-notes-list/${appendix}/${reportingDate}`
      );
      const responseData = await response.data.data;
      if (responseData.length > 0) {
        setisdata(true);
        setisUpdate(true);
        setNotesData(responseData);
      } else {
        setisUpdate(false);
        setShowDialog(true);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const getListofPreviousNotes = async (
    appendix: string,
    reportingDate: string
  ) => {
    setNotesData([]);
    try {
      const response = await axiosInstance.get(
        `/appendix-foot-notes-last-list/${appendix}/${reportingDate}`
      );
      const responseData = await response.data.data;

      if (responseData.length > 0) {
        setisdata(true);
        setisUpdate(false);
        setNotesData(responseData);
      } else {
        toast.error("No notes found for the previous quarter.");
        setisdata(true);
        setisUpdate(false);
        setNotesData([
          ...notesData,
          {
            _id: "",
            appendix_Name: selectedappendix,
            note_Number: 1,
            note_data: "",
            note_Image: "",
            Reporting_Date: `${selectedQuarter}-${selectedYear}`,
            appendix_ID: "",
          },
        ]);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const addUpdateNotes = async () => {
    try {
      const filteredNotesData = notesData.filter(
        (note) => note.note_data !== "" || note.note_Image !== ""
      );
      if (filteredNotesData.length === 0) {
        toast.error("Please add at least one valid note to proceed.");
        return;
      }
      const form = new FormData();
      form.append("Reporting_Date", selectedQuarter + "-" + selectedYear);
      form.append("appendix", selectedappendix);
      form.append("formData", JSON.stringify(filteredNotesData));
      const response = await axiosInstance.post(`/appendix-foot-notes`, form);
      if (response.data === "error") {
        toast.error(`Error while ${isUpdate ? "updaing" : "adding"} notes.`);
      } else {
        toast.success(`Notes ${isUpdate ? "updated" : "added"} successfully.`);
      }
    } catch (error) {
      toast.error("Error while removing notes.");
      console.error(error);
    } finally {
      setisdata(false);
      getListOfNotes(selectedappendix, selectedQuarter + "-" + selectedYear);
    }
  };
  const deleteNote = async (NotesNumber: string) => {
    try {
      const response = await axiosInstance.delete(
        `/appendix-foot-notes/${selectedappendix}/${NotesNumber}/${
          selectedQuarter + "-" + selectedYear
        }`
      );
      if (response.data === "error") {
        toast.error("Error while removing notes.");
      } else {
        toast.success("Notes removed successfully.");
      }
    } catch (error) {
      toast.error("Error while removing notes.");
      console.error(error);
    } finally {
      setisdata(false);
      getListOfNotes(selectedappendix, selectedQuarter + "-" + selectedYear);
    }
  };

  useEffect(() => {
    document.title = "Add Data";
    const dynamicYears = generateYears(2000);
    setYears(dynamicYears);
    const { latestQuarter, latestYear } = getLatestQuarterAndYear();
    setSelectedQuarter(latestQuarter);
    setSelectedYear(latestYear.toString());
  }, []);
  return (
    <React.Fragment>
      <Helmet title="Blank" />
      <Typography variant="h3" gutterBottom display="inline">
        Appendix Notes
      </Typography>
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
        }}
      >
        <Breadcrumbs aria-label="Breadcrumb" mt={2}>
          <Link component={NavLink} to="/">
            Dashboard
          </Link>
          <Link component={NavLink} to="/workFlow">
            WorkFlow
          </Link>
          <Typography>Appendix Notes</Typography>
        </Breadcrumbs>
      </div>
      <Divider my={6} />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Card mb={6}>
            <CardContent>
              <Box
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
                width="100%"
              >
                <Grid container spacing={2} alignItems="center">
                  <Grid item>
                    <FormControl
                      style={{ width: "200px", marginRight: "25px" }}
                    >
                      <InputLabel>Appendix</InputLabel>
                      <Select
                        value={selectedappendix}
                        onChange={handleAppendixChanage}
                        label="Quarter"
                      >
                        {Appendix.map((quarter) => (
                          <MenuItem key={quarter.name} value={quarter.value}>
                            {quarter.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <FormControl style={{ width: "150px" }}>
                      <InputLabel>Quarter</InputLabel>
                      <Select
                        value={selectedQuarter}
                        onChange={handleQuarterChange}
                        label="Quarter"
                      >
                        {quarters.map((quarter) => (
                          <MenuItem key={quarter.name} value={quarter.value}>
                            {quarter.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item>
                    <FormControl style={{ width: "150px" }}>
                      <InputLabel>Year</InputLabel>
                      <Select
                        MenuProps={{
                          PaperProps: {
                            style: {
                              maxHeight: 300,
                            },
                          },
                        }}
                        value={selectedYear}
                        onChange={handleYearChange}
                        label="Year"
                      >
                        {years.map((year) => (
                          <MenuItem key={year} value={year}>
                            {year}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item>
                    <Button
                      onClick={() => {
                        setisdata(false);
                        getListOfNotes(
                          selectedappendix,
                          selectedQuarter + "-" + selectedYear
                        );
                      }}
                      style={{
                        margin: "5px",
                        marginLeft: "20px",
                        backgroundColor: "#007bff",
                        borderRadius: "18px",
                      }}
                      variant="contained"
                    >
                      Retrieve Data
                    </Button>
                  </Grid>
                </Grid>
              </Box>
              {isdata && (
                <form
                  ref={form}
                  onSubmit={(e: FormEvent<HTMLFormElement>) => {
                    e.preventDefault();
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    style={{ marginTop: "30px", textAlign: "center" }}
                  >
                    <Button
                      onClick={() => openDialog("addUpdate")}
                      style={{
                        margin: "5px",
                        marginBottom: "20px",
                        backgroundColor: "#007bff",
                        borderRadius: "18px",
                      }}
                      variant="contained"
                    >
                      {isUpdate ? "Update" : "Add"} Notes
                    </Button>
                    <Grid container spacing={2}>
                      <table
                        style={{
                          textAlign: "left",
                          width: "100%",
                          borderCollapse: "separate",
                          borderSpacing: "1px",
                        }}
                      >
                        <thead>
                          <tr
                            style={{
                              backgroundColor: "#f2f2f2",
                            }}
                          >
                            <th
                              style={{
                                ...styles.tableLabel,
                                width: "15%",
                                textAlign: "center",
                              }}
                            >
                              Notes No.
                            </th>
                            <th
                              style={{
                                ...styles.tableLabel,
                                textAlign: "center",
                                width: "30%",
                                marginLeft: "20px",
                              }}
                            >
                              Update Notes
                            </th>
                            <th
                              style={{
                                ...styles.tableLabel,
                                textAlign: "center",
                                width: "30%",
                              }}
                            >
                              Image
                            </th>
                            <th
                              style={{
                                ...styles.tableLabel,
                                textAlign: "center",
                                width: "10%",
                              }}
                            >
                              Remove Note
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {notesData.map((note, index) => (
                            <tr
                              key={index}
                              style={{
                                paddingTop: "30px",
                                borderBottom: "1px solid #cccccc",
                                backgroundColor:
                                  index % 2 === 0 ? "#FFFFFF" : "#f2f2f2",
                              }}
                            >
                              <td
                                style={{
                                  ...styles.label,
                                  paddingBottom: "10px",
                                  width: "15%",
                                  textAlign: "center",
                                }}
                              >
                                {index + 1}
                              </td>
                              <td style={{ padding: "10px", width: "35%" }}>
                                <TextField
                                  multiline
                                  minRows={1}
                                  maxRows={6}
                                  fullWidth
                                  variant="outlined"
                                  value={note.note_data}
                                  onChange={(e) =>
                                    handleDescriptionChange(
                                      index,
                                      e.target.value
                                    )
                                  }
                                />
                              </td>
                              <td
                                style={{
                                  ...styles.label,
                                  paddingBottom: "10px",
                                  textAlign: "center",
                                  width: "35%",
                                }}
                              >
                                <Grid container spacing={2}>
                                  <Grid
                                    item
                                    xs={6}
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <img
                                      src={note.note_Image}
                                      alt={note.note_Image}
                                      style={{
                                        maxWidth: "125px",
                                        maxHeight: "100px",
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        handleClickOpen(note.note_Image)
                                      }
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    xs={6}
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <input
                                      type="file"
                                      accept="image/*"
                                      style={{
                                        padding: "10px",
                                        border: "1px solid #ccc",
                                        borderRadius: "4px",
                                        width: "100%",
                                        height: "100px",
                                      }}
                                      onChange={(e) =>
                                        handleImageChange(index, e)
                                      }
                                    />
                                  </Grid>
                                </Grid>
                              </td>

                              <td
                                style={{
                                  ...styles.label,
                                  paddingBottom: "10px",
                                  textAlign: "center",
                                  width: "5%",
                                }}
                              >
                                <DeleteOutlineIcon
                                  onClick={() => openDialog("delete", index)}
                                  style={{
                                    cursor: "pointer",
                                    marginLeft: "10px",
                                    marginTop: "10px",
                                  }}
                                />
                              </td>
                            </tr>
                          ))}
                          <tr>
                            <td colSpan={4} style={{ textAlign: "end" }}>
                              <Button
                                onClick={handleAddNote}
                                style={{
                                  margin: "5px",
                                  marginTop: "15px",
                                  backgroundColor: "#007bff",
                                  borderRadius: "18px",
                                }}
                                variant="contained"
                              >
                                + Add New Note
                              </Button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Grid>
                  </Grid>
                </form>
              )}
            </CardContent>
          </Card>
        </Grid>
        <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
          <DialogContent>
            <img src={selectedImage} alt="Selected" style={{ width: "100%" }} />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={showDialog} onClose={() => setShowDialog(false)}>
          <DialogTitle
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <strong>No Notes Found</strong>
          </DialogTitle>

          <DialogContent>
            <Typography variant="subtitle2" fontSize={"17px"}>
              No notes found for the selected date. How would you like to
              proceed?
            </Typography>

            <ul>
              <li>
                <Typography variant="subtitle2" fontSize={"14px"}>
                  <strong>Retrieve Previous Quarter Data:</strong> This will
                  retrieve all the notes from the{" "}
                  {getPreviousQuarterEndDate(
                    selectedQuarter + "-" + selectedYear
                  )}
                  .
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle2" fontSize={"14px"}>
                  <strong>Add Data Manually:</strong> This will provide an empty
                  form to add notes manually.
                </Typography>
              </li>
            </ul>
          </DialogContent>

          <DialogActions
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              onClick={handlePreviousRetrival}
              style={{
                margin: "5px",
                marginLeft: "20px",
                backgroundColor: "#00C04B",
                borderRadius: "18px",
              }}
              variant="contained"
            >
              Retrieve Previous Quarter Data
            </Button>
            <Button
              onClick={handleAddDataManually}
              style={{
                margin: "5px",
                marginLeft: "20px",
                backgroundColor: "#007bff",
                borderRadius: "18px",
              }}
              variant="contained"
            >
              Add Data Manually
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={isDialogOpen} onClose={closeDialog}>
          <DialogTitle id="confirmation-dialog-title">
            {actionType === "delete"
              ? "Delete Note"
              : `${isUpdate ? "Update" : "Add"} Note`}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="confirmation-dialog-description">
              {actionType === "delete"
                ? "Are you sure you want to delete this note?"
                : `Are you sure you want to ${
                    isUpdate ? "Update" : "Add"
                  } this note?`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDialog} color="primary">
              Cancel
            </Button>
            <Button onClick={confirmAction} color="primary" autoFocus>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </React.Fragment>
  );
}
UpdateNotes.getLayout = function getLayout(page: ReactElement) {
  return <DashboardLayout>{page}</DashboardLayout>;
};
export default UpdateNotes;
